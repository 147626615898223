import axios from "axios";
class ApiSourceFiles {
    static async GetFiles(session_uuid) {
        try {
            const result = await axios.get((process.env.VUE_APP_API_PATH || "/") + "api/cms/source-files/get-files", {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.data;
        }
        catch (e) {
            throw new Error("Error get files");
        }
    }
    static async GetFileByUUID(session_uuid, uuid) {
        try {
            const result = await axios.post((process.env.VUE_APP_API_PATH || "/") + "api/cms/source-files/get-files-by", {
                uuid: uuid,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.data;
        }
        catch (e) {
            return undefined;
        }
    }
    static async CreateFile(session_uuid, file_name, description, publish, anchor) {
        try {
            const result = await axios.post((process.env.VUE_APP_API_PATH || "/") + "api/cms/source-files/create-file", {
                file_name: file_name,
                description: description,
                publish: publish,
                anchor: anchor,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            console.error(e);
            if ("response" in e &&
                "data" in e.response &&
                "response" in e.response.data) {
                return e.response.data.response;
            }
        }
        return undefined;
    }
    static async UpdateFilePublish(publish, session_uuid, uuid) {
        try {
            const result = await axios.post((process.env.VUE_APP_API_PATH || "/") + "api/cms/source-files/change-file-publish", {
                uuid: uuid,
                publish: publish,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error");
        }
    }
    static async UpdateFileInfo(file_name, description, publish, session_uuid, uuid, anchor) {
        try {
            const result = await axios.post((process.env.VUE_APP_API_PATH || "/") + "api/cms/source-files/change-file-info", {
                file_name: file_name,
                description: description,
                publish: publish,
                uuid: uuid,
                anchor: anchor,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error");
        }
    }
    static async DeleteFile(session_uuid, uuid) {
        try {
            const result = await axios.post((process.env.VUE_APP_API_PATH || "/") + "api/cms/source-files/delete-file", {
                uuid: uuid,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return undefined;
        }
    }
}
export default ApiSourceFiles;
