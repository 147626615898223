import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import sweetalert from "sweetalert";
import ApiEnter from "@/api/ApiEnter";
import ApiSupportFiles from "@/api/ApiSupportFiles";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
import LeftMenuTab from "@/components/LeftMenu/LeftMenuTab.vue";
import PageHeader from "@/components/UI/PageHeader.vue";
import DataSupportFiles from "@/data/Files/DataSupportFiles";
import { Getter } from "vuex-class";
import EUserEnum from "@/struct/user/EUserEnum";
let FaqEdit = class FaqEdit extends Vue {
    constructor() {
        super(...arguments);
        this.Breadcrumbs = DataSupportFiles.FilesListBreadcrumbs;
        this.TableHeaders = DataSupportFiles.FilesListTableHeaders;
        this.TableItems = [];
        this.FileCategories = [];
    }
    CanAddAndEditAccess() {
        return (this.GetCurrentSessionUser != undefined &&
            (this.GetCurrentSessionUser.system_role == EUserEnum.SystemRole.Admin ||
                this.GetCurrentSessionUser.system_role ==
                    EUserEnum.SystemRole.SuperAdmin ||
                this.GetCurrentSessionUser.system_role == EUserEnum.SystemRole.Editor));
    }
    CanDeleteAccess() {
        return (this.GetCurrentSessionUser != undefined &&
            (this.GetCurrentSessionUser.system_role == EUserEnum.SystemRole.Admin ||
                this.GetCurrentSessionUser.system_role ==
                    EUserEnum.SystemRole.SuperAdmin));
    }
    async ChangeFilePublish(item) {
        try {
            const response = await ApiSupportFiles.UpdateFilePublish(item.publish ? 1 : 0, ApiEnter.CurrentSessionUUID, item.uuid);
            if (typeof response == "boolean") {
                sweetalert({
                    title: this.$t("general.success"),
                    text: this.$t("general.success_created"),
                    icon: "success",
                }).then(() => {
                    this.GetFiles();
                });
            }
            else {
                sweetalert({
                    title: this.$t("general.error"),
                    text: this.$t("general.request_error"),
                    icon: "info",
                });
            }
        }
        catch (e) {
            console.error(e);
            sweetalert({
                icon: "info",
            });
        }
    }
    DeleteFile(file_uui) {
        sweetalert({
            title: this.$t("general.are_you_sure"),
            text: this.$t("files.accept_delete"),
            buttons: [
                this.$t("general.no"),
                this.$t("general.yes"),
            ],
        }).then(async (isConfirm) => {
            if (isConfirm == true) {
                const response = await ApiSupportFiles.DeleteFile(ApiEnter.CurrentSessionUUID, file_uui);
                if (typeof response == "boolean") {
                    sweetalert({
                        title: this.$t("general.success"),
                        text: this.$t("general.success_deleted"),
                        icon: "success",
                    });
                    this.GetFiles();
                }
                else {
                    sweetalert({
                        title: this.$t("general.error"),
                        text: this.$t("general.request_error"),
                        icon: "info",
                    });
                }
            }
        });
    }
    async GetCategories() {
        try {
            this.FileCategories = await ApiSupportFiles.GetFilesCategories(ApiEnter.CurrentSessionUUID);
        }
        catch (e) {
            console.error(e);
        }
    }
    async GetFiles() {
        try {
            this.TableItems = await ApiSupportFiles.GetFiles(ApiEnter.CurrentSessionUUID);
        }
        catch (e) {
            console.error(e);
        }
    }
    mounted() {
        this.GetCategories();
        this.GetFiles();
    }
};
__decorate([
    Getter("getCurrentSessionUser")
], FaqEdit.prototype, "GetCurrentSessionUser", void 0);
FaqEdit = __decorate([
    Component({
        components: { PageHeader, StandartTemplate, LeftMenuTab },
    })
], FaqEdit);
export default FaqEdit;
