var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:'component-container input-enter-component input-enter-email-component form-row mb-3 mt-5 ' +
    (_vm.hasValueFocusStatus ? 'focused ' : '') +
    (_vm.privateValueField.length > 0 && !_vm.hasValueValidStatus
      ? 'tooltip-show '
      : '')},[_c('input-text-from-enter',{attrs:{"name":"Email"},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.privateValueField),expression:"privateValueField"}],class:{
          'form-control': true,
          'is-valid': _vm.hasValueValidStatus,
          'is-invalid': !_vm.hasValueValidStatus && _vm.privateValueField.length > 0,
        },attrs:{"type":"email","maxlength":"128","autocomplete":"off","autocapitalize":"off","readonly":_vm.readonly,"aria-autocomplete":"none","tabindex":"0"},domProps:{"value":(_vm.privateValueField)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.privateValueField=$event.target.value},_vm.OnInputEvent],"focusout":_vm.OnFocusOut,"focusin":_vm.OnFocusIn}})]},proxy:true},{key:"tooltip",fn:function(){return [_c('ul',[_c('li',[_vm._v("Введите корректны Email адрес.")])])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }