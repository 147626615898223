import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import PageHeader from "@/components/UI/PageHeader.vue";
import ApiShopOptions from "@/api/ApiShopOptions";
import ApiEnter from "@/api/ApiEnter";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
import DataOptions from "@/data/AdminPanel/DataOptions";
import sweetalert from "sweetalert";
import { Getter } from "vuex-class";
import EUserEnum from "@/struct/user/EUserEnum";
let Options = class Options extends Vue {
    constructor() {
        super(...arguments);
        this.Breadcrumbs = DataOptions.Breadcrumbs;
        this.TableHeaders = DataOptions.TableHeaders;
        this.TableItems = [];
    }
    async GetOptions() {
        try {
            this.TableItems = await ApiShopOptions.GetOptions(ApiEnter.CurrentSessionUUID);
        }
        catch (e) {
            console.error(e);
        }
    }
    CanAdminAccess() {
        return (this.GetCurrentSessionUser != undefined &&
            this.GetCurrentSessionUser.system_role == EUserEnum.SystemRole.SuperAdmin);
    }
    DeleteOption(option_uuid) {
        sweetalert({
            title: this.$t("general.confirm"),
            text: this.$t("options.accept_delete"),
            icon: "warning",
            buttons: [
                this.$t("general.no"),
                this.$t("general.yes"),
            ],
        }).then(async (isConfirm) => {
            if (isConfirm == true) {
                const response = await ApiShopOptions.DeleteOption(ApiEnter.CurrentSessionUUID, option_uuid);
                if (typeof response == "boolean") {
                    await sweetalert({
                        title: this.$t("general.success"),
                        text: this.$t("general.success_deleted"),
                        icon: "success",
                    });
                    await this.GetOptions();
                }
                else {
                    await sweetalert({
                        title: this.$t("general.error"),
                        text: this.$t("general.request_error"),
                        icon: "info",
                    });
                }
            }
        });
    }
    async ChangeOptionPublish(item) {
        try {
            const response = await ApiShopOptions.UpdateOptionPublish(item.publish ? 1 : 0, ApiEnter.CurrentSessionUUID, item.uuid);
            if (typeof response == "boolean") {
                sweetalert({
                    title: this.$t("general.success"),
                    text: this.$t("general.success_updated"),
                    icon: "success",
                }).then(() => {
                    this.GetOptions();
                });
            }
            else {
                await sweetalert({
                    title: this.$t("general.error"),
                    text: this.$t("general.request_error"),
                    icon: "info",
                });
            }
        }
        catch (e) {
            console.error(e);
            await sweetalert({
                title: this.$t("general.error"),
                text: this.$t("general.request_error"),
                icon: "info",
            });
        }
    }
    created() {
        this.GetOptions();
    }
};
__decorate([
    Getter("getCurrentSessionUser")
], Options.prototype, "GetCurrentSessionUser", void 0);
Options = __decorate([
    Component({
        components: { StandartTemplate, PageHeader },
    })
], Options);
export default Options;
