import axios from "axios";
export default class ApiOrders {
    static async GetOrdersList(session_uuid) {
        try {
            const result = await axios.get((process.env.VUE_APP_API_PATH || "/") + "api/web-api/orders/get-list", {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            console.error("Error to get orders", e);
            return false;
        }
    }
    static async GetOrderByUUID(session_uuid, uuid) {
        try {
            const result = await axios.get((process.env.VUE_APP_API_PATH || "/") + "api/web-api/order/get?uuid=" + uuid, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return false;
        }
    }
    static async RefreshOrderStatuses(session_uuid) {
        try {
            const result = await axios.get((process.env.VUE_APP_API_PATH || "/") + "api/web-api/order/refresh-statuses", {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return false;
        }
    }
    static async UpdateNote(session_uuid, uuid, note) {
        try {
            const result = await axios.post((process.env.VUE_APP_API_PATH || "/") + "api/web-api/order/edit-note", {
                uuid: uuid,
                note: note,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return false;
        }
    }
    static async UpdateContactInfo(session_uuid, uuid, phone, email) {
        try {
            const result = await axios.post((process.env.VUE_APP_API_PATH || "/") + "api/web-api/order/update-contact-info", {
                uuid: uuid,
                phone: phone,
                email: email,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return false;
        }
    }
    static async UpdateDeliveryAddress(session_uuid, uuid, street_name, street_number, city, province, zip_code, country) {
        try {
            const result = await axios.post((process.env.VUE_APP_API_PATH || "/") + "api/web-api/order/update-delivery-address", {
                uuid: uuid,
                street_name: street_name,
                street_number: street_number,
                city: city,
                province: province,
                zip_code: zip_code,
                country: country,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return false;
        }
    }
    static async UpdateDeliveryTracking(session_uuid, uuid, tracking, tracking_link) {
        try {
            const result = await axios.post((process.env.VUE_APP_API_PATH || "/") + "api/web-api/order/update-tracking", {
                uuid: uuid,
                tracking: tracking,
                tracking_link: tracking_link,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return false;
        }
    }
    static async GetOrderHistory(session_uuid, order_uuid) {
        try {
            const result = await axios.get((process.env.VUE_APP_API_PATH || "/") + "api/web-api/order/get-history?order_uuid=" + order_uuid, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return false;
        }
    }
}
