import axios from "axios";
class ApiShopOptions {
    static async GetOptions(session_uuid) {
        try {
            const result = await axios.get((process.env.VUE_APP_API_PATH || "/") + "api/shop/options/get-options", {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.data;
        }
        catch (e) {
            throw new Error("Error get options");
        }
    }
    static async GetOptionByUUID(session_uuid, uuid) {
        try {
            const result = await axios.post((process.env.VUE_APP_API_PATH || "/") + "api/shop/options/get-option-by", {
                uuid: uuid,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.data;
        }
        catch (e) {
            return undefined;
        }
    }
    static async CreateOption(session_uuid, name, name_mobile, priority, publish, type, ems_product_name, ems_product_id, description, block_one_title, block_one_body, block_two_title, block_two_body, roaming, available_to_activate, available_to_deactivate) {
        try {
            const result = await axios.post((process.env.VUE_APP_API_PATH || "/") + "api/shop/options/create-option", {
                name: name,
                name_mobile: name,
                priority: priority,
                publish: publish,
                type: type,
                ems_product_name: ems_product_name,
                ems_product_id: ems_product_id,
                description: description,
                block_one_title: block_one_title,
                block_one_body: block_one_body,
                block_two_title: block_two_title,
                block_two_body: block_two_body,
                roaming: roaming,
                available_to_activate: available_to_activate,
                available_to_deactivate: available_to_deactivate,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            console.error(e);
            if ("response" in e &&
                "data" in e.response &&
                "response" in e.response.data) {
                return e.response.data.response;
            }
        }
        return undefined;
    }
    static async UpdateOptionPublish(publish, session_uuid, uuid) {
        try {
            const result = await axios.post((process.env.VUE_APP_API_PATH || "/") + "api/shop/options/change-option-publish", {
                uuid: uuid,
                publish: publish,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error");
        }
    }
    static async UpdateOptionInfo(name, name_mobile, priority, publish, type, ems_product_name, ems_product_id, description, uuid, session_uuid, block_one_title, block_one_body, block_two_title, block_two_body, roaming, available_to_activate, available_to_deactivate) {
        try {
            const result = await axios.post((process.env.VUE_APP_API_PATH || "/") + "api/shop/options/change-option-info", {
                uuid: uuid,
                name: name,
                name_mobile: name_mobile,
                priority: priority,
                publish: publish,
                type: type,
                ems_product_name: ems_product_name,
                ems_product_id: ems_product_id,
                description: description,
                block_one_title: block_one_title,
                block_one_body: block_one_body,
                block_two_title: block_two_title,
                block_two_body: block_two_body,
                roaming: roaming,
                available_to_activate: available_to_activate,
                available_to_deactivate: available_to_deactivate,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error");
        }
    }
    static async DeleteOption(session_uuid, uuid) {
        try {
            const result = await axios.post((process.env.VUE_APP_API_PATH || "/") + "api/shop/options/delete-option", {
                uuid: uuid,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return undefined;
        }
    }
}
export default ApiShopOptions;
