import { __decorate } from "tslib";
import { Component, Prop, Vue } from "vue-property-decorator";
import InputTextFromEnter from "@/components/Template/Enter/InputTextFromEnter.vue";
let InputEnterPassword = class InputEnterPassword extends Vue {
    created() {
        this.privateValueField = this.value;
        this.hasValueValidStatus = false;
        this.hasValueFocusStatus = false;
    }
    OnFocusIn() {
        this.hasValueFocusStatus = true;
        this.$forceUpdate();
    }
    OnFocusOut() {
        this.hasValueFocusStatus = false;
        this.$forceUpdate();
    }
    OnInputEvent() {
        this.$emit("input", this.privateValueField);
        this.DoValidate();
    }
    DoValidate() {
        let newValidState = this.privateValueField.trim().length >= 6 &&
            this.privateValueField.trim().length <= 32;
        if (newValidState == true && this.confirm) {
            newValidState =
                document.querySelector('input[name="pass1"]')
                    .value == this.privateValueField;
        }
        if (newValidState != this.hasValueValidStatus) {
            this.$emit("valid", newValidState);
        }
        this.hasValueValidStatus = newValidState;
        this.ForceUpdate();
    }
    ForceUpdate() {
        this.$forceUpdate();
        for (const childItem of this.$children) {
            childItem.$forceUpdate();
        }
    }
};
__decorate([
    Prop({ default: "" })
], InputEnterPassword.prototype, "value", void 0);
__decorate([
    Prop({ default: false })
], InputEnterPassword.prototype, "forgod", void 0);
__decorate([
    Prop({ default: false })
], InputEnterPassword.prototype, "confirm", void 0);
__decorate([
    Prop({ default: false })
], InputEnterPassword.prototype, "readonly", void 0);
InputEnterPassword = __decorate([
    Component({
        components: { InputTextFromEnter },
    })
], InputEnterPassword);
export default InputEnterPassword;
