import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import PageHeader from "@/components/UI/PageHeader.vue";
import DataCompany from "@/data/AdminPanel/DataCompany";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
import ApiAdmin from "@/api/ApiAdmin";
import ApiEnter from "@/api/ApiEnter";
let Users = class Users extends Vue {
    constructor() {
        super(...arguments);
        this.Breadcrumbs = DataCompany.Breadcrumbs;
        this.TableHeaders = DataCompany.TableHeaders;
        this.TableItems = [];
    }
    async GetCompanies() {
        try {
            this.TableItems = await ApiAdmin.GetCompanies(ApiEnter.CurrentSessionUUID);
        }
        catch (e) {
            console.error(e);
        }
    }
    EditCompany(company_uuid) {
        this.$router.push(`/secure/admin/edit-company/${company_uuid}`);
    }
    created() {
        this.GetCompanies();
    }
};
Users = __decorate([
    Component({
        components: { StandartTemplate, PageHeader },
    })
], Users);
export default Users;
