import { __decorate } from "tslib";
import sweetalert from "sweetalert";
import { Component, Vue } from "vue-property-decorator";
import EnterTemplate from "@/components/Template/EnterTemplate.vue";
import InputTextFromEnter from "@/components/Template/Enter/InputTextFromEnter.vue";
import InputEnterEmail from "@/components/Enter/InputEnterEmail.vue";
import InputEnterPassword from "@/components/Enter/InputEnterPassword.vue";
import ApiEnter from "@/api/ApiEnter";
import { Action, Mutation } from "vuex-class";
let Login = class Login extends Vue {
    constructor() {
        super(...arguments);
        this.Form = {
            Email: {
                value: "",
                empty: true,
                focused: false,
                valid: false,
            },
            Password: {
                value: "",
                empty: true,
                focused: false,
                valid: false,
            },
        };
    }
    created() {
        this.HasValidForm = false;
        this.HasLoginRequest = false;
        if (this.$route.params?.email != undefined) {
            this.Form.Email.value = this.$route.params.email;
            this.OnInputChanged(this.Form.Email);
        }
        document.title = "Login";
    }
    GetClass_FormRow(model) {
        let result = " form-row";
        result += model.focused ? " focused " : "";
        result += !model.empty && !model.valid ? " tooltip-show " : "";
        return result;
    }
    GetClass_FormRowInput(model) {
        let result = " form-control";
        result += model.valid ? " is-valid" : "";
        result += !model.valid && !model.empty ? " is-invalid" : "";
        return result;
    }
    OnInputFocusIn(model) {
        model.focused = true;
        this.$forceUpdate();
    }
    OnInputFocusOut(model) {
        model.focused = false;
        this.$forceUpdate();
    }
    SetInputValue(model, line) {
        model.value = line;
        this.OnInputChanged(model);
    }
    OnInputChanged(model) {
        let newValidState = false;
        model.empty = model.value.length == 0;
        if (model == this.Form.Email) {
            newValidState =
                model.value
                    .toLowerCase()
                    .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) != null;
        }
        else if (model == this.Form.Password) {
            newValidState = model.value.length >= 6 && model.value.length <= 32;
        }
        if (newValidState != model.valid) {
            model.valid = newValidState;
            this.HasValidForm =
                this.Form.Email.valid == true && this.Form.Password.valid == true;
        }
        this.$forceUpdate();
    }
    OnSubmit() {
        if (this.HasValidForm == false) {
            sweetalert({
                title: this.$t("general.error"),
                icon: "info",
            });
            return;
        }
        const email = this.Form.Email.value;
        const password = this.Form.Password.value;
        this.SetInputValue(this.Form.Password, "");
        this.HasLoginRequest = true;
        this.$forceUpdate();
        ApiEnter.Login(email, password)
            .then(async (session_uuid) => {
            await this.DoUpdateCurrentSessionUUID(session_uuid);
            this.SetCurrentTenant(undefined);
        })
            .catch((err) => {
            if ("response" in err == false ||
                (err?.response?.status || 599) >= 500) {
                sweetalert({
                    title: this.$t("general.error"),
                    text: this.$t("general.request_error"),
                    icon: "error",
                });
            }
            else {
                sweetalert({
                    title: this.$t("general.error"),
                    text: this.$t("general.request_error"),
                    icon: "error",
                });
            }
        })
            .finally(() => {
            this.HasLoginRequest = false;
            this.$forceUpdate();
        });
    }
};
__decorate([
    Action("doUpdateCurrentSessionUUID")
], Login.prototype, "DoUpdateCurrentSessionUUID", void 0);
__decorate([
    Mutation("setCurrentTenant")
], Login.prototype, "SetCurrentTenant", void 0);
Login = __decorate([
    Component({
        components: {
            InputEnterPassword,
            InputEnterEmail,
            InputTextFromEnter,
            EnterTemplate,
        },
    })
], Login);
export default Login;
