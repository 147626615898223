import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import PageHeader from "@/components/UI/PageHeader.vue";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
import ApiEnter from "@/api/ApiEnter";
import ApiShopOptions from "@/api/ApiShopOptions";
import sweetalert from "sweetalert";
import DataOptions from "@/data/AdminPanel/DataOptions";
import dayjs from "dayjs";
import ApiOffer from "@/api/ApiOffer";
let OptionsEdit = class OptionsEdit extends Vue {
    constructor() {
        super(...arguments);
        this.Breadcrumbs = DataOptions.BreadcrumbsEdit;
        this.newOption = {
            name: "",
            name_for_mobile: "",
            description: "",
            priority: "",
            publish: true,
            roaming: true,
            type: "",
            blockOneTitle: "",
            blockOneBody: "",
            blockTwoTitle: "",
            blockTwoBody: "",
            available_to_activate: false,
            available_to_deactivate: false,
        };
        this.emsOffers = [];
        this.selectOffer = null;
        this.emsOffer = null;
        this.HasLoading = false;
    }
    async OnClickSubmit() {
        this.HasLoading = true;
        try {
            const response = await ApiShopOptions.UpdateOptionInfo(this.newOption.name, this.newOption.name_for_mobile, this.newOption.priority, this.newOption.publish ? 1 : 0, this.newOption.type, this.emsOffer ? this.emsOffer?.name : null, this.emsOffer ? this.emsOffer?.id : null, this.newOption.description, this.CurrentOptionUUID, ApiEnter.CurrentSessionUUID, this.newOption.blockOneTitle, this.newOption.blockOneBody, this.newOption.blockTwoTitle, this.newOption.blockTwoBody, this.newOption.roaming ? 1 : 0, this.newOption.available_to_activate ? 1 : 0, this.newOption.available_to_deactivate ? 1 : 0);
            if (typeof response == "boolean") {
                sweetalert({
                    title: this.$t("general.success"),
                    text: this.$t("general.success_updated"),
                    icon: "success",
                }).then(() => {
                    this.$router.go(-1);
                });
            }
            else {
                sweetalert({
                    title: this.$t("general.error"),
                    text: this.$t("general.request_error"),
                    icon: "info",
                });
            }
        }
        catch (e) {
            console.error(e);
            sweetalert({
                title: this.$t("general.error"),
                text: this.$t("general.request_error"),
                icon: "info",
            });
        }
        this.HasLoading = false;
    }
    async DoLoadForm() {
        const optionInfo = await ApiShopOptions.GetOptionByUUID(ApiEnter.CurrentSessionUUID, this.CurrentOptionUUID);
        if (optionInfo == undefined) {
            sweetalert({
                title: this.$t("general.error"),
                text: this.$t("general.request_error"),
                icon: "info",
            }).then(() => {
                this.$router.go(-1);
            });
            return;
        }
        this.newOption.name = optionInfo.name;
        this.newOption.name_for_mobile = optionInfo.name_for_mobile;
        this.newOption.description = optionInfo.description;
        this.newOption.priority = optionInfo.priority;
        this.newOption.publish = optionInfo.publish;
        this.newOption.type = optionInfo.type;
        this.newOption.blockOneTitle = optionInfo.block_one_title;
        this.newOption.blockOneBody = optionInfo.block_one_body;
        this.newOption.blockTwoTitle = optionInfo.block_two_title;
        this.newOption.blockTwoBody = optionInfo.block_two_body;
        this.newOption.roaming = optionInfo.roaming;
        this.newOption.available_to_activate = optionInfo.available_to_activate;
        this.newOption.available_to_deactivate = optionInfo.available_to_deactivate;
        this.newOption.created = dayjs(optionInfo.create_at).format("DD.MM.YYYY HH:mm");
        if (optionInfo.ems_product_name != null &&
            optionInfo.ems_product_id != null) {
            this.emsOffer = {
                name: optionInfo.ems_product_name,
                id: optionInfo.ems_product_id,
            };
            this.selectOffer = optionInfo.ems_product_id;
        }
        this.$forceUpdate();
    }
    filterOffer() {
        this.emsOffer = this.emsOffers.find((item) => item.id === this.selectOffer);
    }
    async getCMSOffers() {
        try {
            const response = await ApiOffer.GetEMSOffers(ApiEnter.CurrentSessionUUID, "additional");
            if (typeof response != "boolean") {
                this.emsOffers = response;
            }
        }
        catch (e) {
            console.error(e);
        }
    }
    mounted() {
        this.CurrentOptionUUID = this.$route.params.option_uuid;
        this.getCMSOffers();
        this.DoLoadForm();
    }
};
OptionsEdit = __decorate([
    Component({
        components: { StandartTemplate, PageHeader },
    })
], OptionsEdit);
export default OptionsEdit;
