import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "@/views/Enter/Login.vue";
import Logout from "@/views/Enter/Logout.vue";
import Registration from "@/views/Enter/Registration.vue";
import profile from "@/router/profile";
import adminPanel from "@/router/admin-panel";
import faq from "@/router/faq";
import files from "@/router/files";
import feedback from "@/router/feedback";
import shop from "@/router/shop";
import SourceFiles from "@/views/CMS/SourceFiles/SourceFiles.vue";
import EmailNotifications from "@/views/Settings/EmailNotification/EmailNotifications.vue";
import EmailNotificationsCreate from "@/views/Settings/EmailNotification/EmailNotificationsCreate.vue";
import EmailNotificationsEdit from "@/views/Settings/EmailNotification/EmailNotificationsEdit.vue";
Vue.use(VueRouter);
const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/secure/cms/enter/login",
        name: "EnterLogin",
        component: Login,
    },
    {
        path: "/secure/cms/enter/login/:email",
        name: "EnterLoginFromEmail",
        component: Login,
    },
    {
        path: "/secure/enter/logout",
        name: "EnterLogout",
        component: Logout,
    },
    {
        path: "/secure/cms/enter/registration",
        name: "EnterRegistration",
        component: Registration,
    },
    {
        path: "/secure/cms/source-files",
        name: "SourceFiles",
        component: SourceFiles,
    },
    {
        path: "/secure/cms/settings/email-notifications",
        name: "EmailNotifications",
        component: EmailNotifications,
    },
    {
        path: "/secure/cms/settings/email-notifications/create",
        name: "EmailNotificationsCreate",
        component: EmailNotificationsCreate,
    },
    {
        path: "/secure/cms/settings/email-notifications/edit/:email_uuid",
        name: "EmailNotificationsEdit",
        component: EmailNotificationsEdit,
    },
    ...profile,
    ...adminPanel,
    ...faq,
    ...files,
    ...feedback,
    ...shop,
];
const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});
export default router;
