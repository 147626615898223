import VueI18n from "vue-i18n";
import ru from "@/i18n/ru.json";
import Vue from "vue";
Vue.use(VueI18n);
const messages = {
    ru,
};
export default new VueI18n({
    locale: "ru",
    messages: messages,
});
