var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:'component-container input-enter-component input-enter-password-component form-row mb-3 ' +
    (_vm.hasValueFocusStatus ? 'focused ' : '') +
    (_vm.privateValueField.length > 0 && !_vm.hasValueValidStatus
      ? 'tooltip-show '
      : '')},[_c('input-text-from-enter',{attrs:{"name":_vm.confirm ? 'Повторите пароль' : 'Пароль'},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.privateValueField),expression:"privateValueField"}],class:{
          'form-control': true,
          'is-valid': _vm.hasValueValidStatus,
          'is-invalid': !_vm.hasValueValidStatus && _vm.privateValueField.length > 0,
        },attrs:{"type":"password","name":_vm.confirm ? 'pass2' : 'pass1',"readonly":_vm.readonly,"maxlength":"32","autocomplete":"off","autocapitalize":"off","aria-autocomplete":"none","tabindex":"0"},domProps:{"value":(_vm.privateValueField)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.privateValueField=$event.target.value},_vm.OnInputEvent],"focusout":_vm.OnFocusOut,"focusin":_vm.OnFocusIn}})]},proxy:true},{key:"tooltip",fn:function(){return [_c('ul',[_c('li',[_vm._v("Пароль должен быть от 6 до 32 символов.")])])]},proxy:true},{key:"name-link",fn:function(){return [(_vm.forgod)?_c('router-link',{attrs:{"to":"/enter/forgot-password","tabindex":"-1"}},[_vm._v("Забыли пароль?")]):_vm._e()]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }