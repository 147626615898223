import axios from "axios";
class ApiOffer {
    static async GetOffers(session_uuid) {
        try {
            const result = await axios.get((process.env.VUE_APP_API_PATH || "/") + "api/shop/offer/get-offers", {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.data;
        }
        catch (e) {
            throw new Error("Error get offers");
        }
    }
    static async GetOfferByUUID(session_uuid, offer_uuid) {
        try {
            const result = await axios.post((process.env.VUE_APP_API_PATH || "/") + "api/shop/offer/get-offer-by", {
                offer_uuid: offer_uuid,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.data;
        }
        catch (e) {
            return undefined;
        }
    }
    static async CreateOffer(session_uuid, name, priority, publish, price, data, sms, min, additional_data, additional_price_info, sticker_text, overview, note, display_offers, display_home, display_for_new, display_for_current, prospects_info, title, description, additional_description, ems_product_id, ems_product_name, keywords, url, service, renewalPrice) {
        try {
            const result = await axios.post((process.env.VUE_APP_API_PATH || "/") + "api/shop/offer/offer-create", {
                name: name,
                priority: priority,
                publish: publish,
                price: price,
                renewalPrice: renewalPrice,
                data: data,
                sms: sms,
                min: min,
                additional_data: additional_data,
                additional_price_info: additional_price_info,
                sticker_text: sticker_text,
                overview: overview,
                note: note,
                display_offers: display_offers,
                display_home: display_home,
                display_for_new: display_for_new,
                display_for_current: display_for_current,
                prospects_info: prospects_info,
                title: title,
                description: description,
                additional_description: additional_description,
                ems_product_id: ems_product_id,
                ems_product_name: ems_product_name,
                keywords: keywords,
                url: url,
                service: service,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            console.error(e);
            if ("response" in e &&
                "data" in e.response &&
                "response" in e.response.data) {
                return e.response.data.response;
            }
        }
        return undefined;
    }
    static async UpdateOfferInfo(name, priority, publish, price, data, sms, min, additional_data, additional_price_info, sticker_text, overview, note, display_offers, display_home, display_for_new, display_for_current, tariff_overview, prospects_info, title, description, additional_description, ems_product_id, ems_product_name, keywords, url, renewalPrice, service, session_uuid, uuid) {
        try {
            const result = await axios.post((process.env.VUE_APP_API_PATH || "/") + "api/shop/offer/offer-change", {
                name: name,
                priority: priority,
                publish: publish,
                price: price,
                renewalPrice: renewalPrice,
                data: data,
                sms: sms,
                min: min,
                additional_data: additional_data,
                additional_price_info: additional_price_info,
                sticker_text: sticker_text,
                overview: overview,
                note: note,
                display_offers: display_offers,
                display_home: display_home,
                display_for_new: display_for_new,
                display_for_current: display_for_current,
                tariff_overview: tariff_overview,
                prospects_info: prospects_info,
                title: title,
                description: description,
                additional_description: additional_description,
                ems_product_id: ems_product_id,
                ems_product_name: ems_product_name,
                keywords: keywords,
                url: url,
                service: service,
                uuid: uuid,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error get offer");
        }
    }
    static async UpdateOfferPublish(publish, session_uuid, uuid) {
        try {
            const result = await axios.post((process.env.VUE_APP_API_PATH || "/") + "api/shop/offer/change-offer-publish", {
                uuid: uuid,
                publish: publish,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error");
        }
    }
    static async GetEMSOffers(session_uuid, categories) {
        try {
            const result = await axios.get((process.env.VUE_APP_API_PATH || "/") + "api/admin/get-ems-products?categories=" + categories, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return false;
        }
    }
    static async DeleteOffer(session_uuid, uuid) {
        try {
            const result = await axios.post((process.env.VUE_APP_API_PATH || "/") + "api/shop/offer/delete-offer", {
                uuid: uuid,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return undefined;
        }
    }
    static async GetOfferCategories(session_uuid) {
        try {
            const result = await axios.get((process.env.VUE_APP_API_PATH || "/") + "api/admin/get-offer-categories", {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.data;
        }
        catch (e) {
            throw new Error("Error get blog");
        }
    }
    static async GetOfferCategoryByUUID(session_uuid, offer_category_uuid) {
        try {
            const result = await axios.post((process.env.VUE_APP_API_PATH || "/") + "api/admin/get-offer-category-by", {
                offer_category_uuid: offer_category_uuid,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.data;
        }
        catch (e) {
            return undefined;
        }
    }
    static async CreateOfferCategory(session_uuid, name, priority, publish) {
        try {
            const result = await axios.post((process.env.VUE_APP_API_PATH || "/") + "api/admin/offer-create-category", {
                name: name,
                publish: publish,
                priority: priority,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            console.error(e);
            if ("response" in e &&
                "data" in e.response &&
                "response" in e.response.data) {
                return e.response.data.response;
            }
        }
        return undefined;
    }
    static async UpdateOfferCategoryPublish(publish, session_uuid, uuid) {
        try {
            const result = await axios.post((process.env.VUE_APP_API_PATH || "/") + "api/admin/change-offer-category-publish", {
                uuid: uuid,
                publish: publish,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error");
        }
    }
    static async UpdateOfferCategoryInfo(name, priority, publish, session_uuid, uuid) {
        try {
            const result = await axios.post((process.env.VUE_APP_API_PATH || "/") + "api/admin/change-offer-category-info", {
                uuid: uuid,
                name: name,
                priority: priority,
                publish: publish,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error");
        }
    }
    static async DeleteOfferCategory(session_uuid, uuid) {
        try {
            const result = await axios.post((process.env.VUE_APP_API_PATH || "/") + "api/admin/delete-offer-category", {
                uuid: uuid,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return undefined;
        }
    }
    static async GetActiveOffers(session_uuid) {
        try {
            const result = await axios.get((process.env.VUE_APP_API_PATH || "/") + "api/admin/get-active-offers", {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return false;
        }
    }
    static async UpdateBSSOffers(session_uuid) {
        try {
            const result = await axios.get((process.env.VUE_APP_API_PATH || "/") + "api/web-api/admin/update-bss-offers", {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return false;
        }
    }
}
export default ApiOffer;
