export var EOrderEnum;
(function (EOrderEnum) {
    let OrderStatus;
    (function (OrderStatus) {
        OrderStatus[OrderStatus["NotFinished"] = 0] = "NotFinished";
        OrderStatus[OrderStatus["New"] = 1] = "New";
    })(OrderStatus = EOrderEnum.OrderStatus || (EOrderEnum.OrderStatus = {}));
    let OrderType;
    (function (OrderType) {
        OrderType[OrderType["New"] = 0] = "New";
        OrderType[OrderType["TransferPhone"] = 1] = "TransferPhone";
    })(OrderType = EOrderEnum.OrderType || (EOrderEnum.OrderType = {}));
    let OrderProductSimStatus;
    (function (OrderProductSimStatus) {
        OrderProductSimStatus[OrderProductSimStatus["NoActive"] = 0] = "NoActive";
        OrderProductSimStatus[OrderProductSimStatus["PendingStartActivation"] = 1] = "PendingStartActivation";
        OrderProductSimStatus[OrderProductSimStatus["PendingWaitingKYC"] = 2] = "PendingWaitingKYC";
        OrderProductSimStatus[OrderProductSimStatus["PendingPaymentForNumber"] = 3] = "PendingPaymentForNumber";
        OrderProductSimStatus[OrderProductSimStatus["PendingRepeatKYC"] = 4] = "PendingRepeatKYC";
        OrderProductSimStatus[OrderProductSimStatus["PendingFromStartedKYC"] = 5] = "PendingFromStartedKYC";
        OrderProductSimStatus[OrderProductSimStatus["PendingFromCompletedKYC"] = 6] = "PendingFromCompletedKYC";
        OrderProductSimStatus[OrderProductSimStatus["AcceptedFromCompletedKYC"] = 7] = "AcceptedFromCompletedKYC";
        OrderProductSimStatus[OrderProductSimStatus["CancelledFailedKYC"] = 8] = "CancelledFailedKYC";
        OrderProductSimStatus[OrderProductSimStatus["CancelledByCustomer"] = 9] = "CancelledByCustomer";
        OrderProductSimStatus[OrderProductSimStatus["New"] = 100] = "New";
        OrderProductSimStatus[OrderProductSimStatus["InProcess"] = 101] = "InProcess";
        OrderProductSimStatus[OrderProductSimStatus["InDelivery"] = 102] = "InDelivery";
    })(OrderProductSimStatus = EOrderEnum.OrderProductSimStatus || (EOrderEnum.OrderProductSimStatus = {}));
    let OrderHistoryType;
    (function (OrderHistoryType) {
        OrderHistoryType[OrderHistoryType["OrderCreate"] = 0] = "OrderCreate";
        OrderHistoryType[OrderHistoryType["OrderStatus"] = 1] = "OrderStatus";
        OrderHistoryType[OrderHistoryType["PaymentStatus"] = 2] = "PaymentStatus";
        OrderHistoryType[OrderHistoryType["ProductStatus"] = 3] = "ProductStatus";
        OrderHistoryType[OrderHistoryType["ChangeValue"] = 4] = "ChangeValue";
    })(OrderHistoryType = EOrderEnum.OrderHistoryType || (EOrderEnum.OrderHistoryType = {}));
})(EOrderEnum || (EOrderEnum = {}));
