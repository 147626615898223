import i18n from "@/i18n/index.ts";
export default class DataSupportFiles {
}
DataSupportFiles.FilesListBreadcrumbs = [
    {
        to: "/",
        text: i18n.t("breadcrumbs.home"),
    },
    {
        to: "/secure/cms/files",
        text: i18n.t("breadcrumbs.cms"),
    },
    {
        to: "/secure/cms/files",
        exact: true,
        text: i18n.t("breadcrumbs.support_files"),
    },
    {
        to: "/secure/cms/files",
        text: i18n.t("breadcrumbs.files"),
        disabled: true,
    },
];
DataSupportFiles.FilesListTableHeaders = [
    {
        text: i18n.t("table_headers.sorting"),
        value: "priority",
        width: "10%",
    },
    {
        text: i18n.t("table_headers.file_name"),
        value: "name",
        width: "45%",
    },
    {
        text: i18n.t("table_headers.action"),
        value: "action",
    },
];
DataSupportFiles.CategoriesTableHeaders = [
    {
        text: i18n.t("table_headers.sorting"),
        value: "priority",
        width: "10%",
    },
    {
        text: i18n.t("table_headers.category_name"),
        value: "name",
        width: "60%",
    },
    {
        text: i18n.t("table_headers.action"),
        value: "action",
    },
];
