export default class DataUser {
}
DataUser.Breadcrumbs = [
    {
        to: "/",
        text: "Главная",
    },
    {
        to: "/secure/admin/users",
        text: "Администрирование компаний",
        disabled: true,
    },
];
DataUser.TableHeaders = [
    {
        text: "Название компании",
        value: "name",
    },
    {
        text: "ИНН компании",
        value: "offical_inn",
    },
    {
        text: "ОГРН/ОГРНИП",
        value: "offical_ogrn",
    },
    {
        text: "Адрес",
        value: "address",
    },
    {
        text: "Имя директора",
        value: "ceo_full_name",
    },
    {
        text: "Рассчетный счет",
        value: "bank_rs",
    },
    {
        text: "БИК счёта",
        value: "bik",
    },
    {
        text: "Название банка",
        value: "bank_name",
    },
    {
        text: "Действие",
        value: "action",
    },
];
