var EUserEnum;
(function (EUserEnum) {
    let SystemRole;
    (function (SystemRole) {
        SystemRole[SystemRole["Courier"] = 0] = "Courier";
        SystemRole[SystemRole["Reader"] = 1] = "Reader";
        SystemRole[SystemRole["Editor"] = 2] = "Editor";
        SystemRole[SystemRole["Admin"] = 3] = "Admin";
        SystemRole[SystemRole["SuperAdmin"] = 4] = "SuperAdmin";
        SystemRole[SystemRole["__LENGHT"] = 5] = "__LENGHT";
    })(SystemRole = EUserEnum.SystemRole || (EUserEnum.SystemRole = {}));
    let UserStatus;
    (function (UserStatus) {
        UserStatus[UserStatus["NoActive"] = 0] = "NoActive";
        UserStatus[UserStatus["Active"] = 1] = "Active";
        UserStatus[UserStatus["Created"] = 2] = "Created";
        UserStatus[UserStatus["Banned"] = 3] = "Banned";
        UserStatus[UserStatus["Deleted"] = 4] = "Deleted";
        UserStatus[UserStatus["__LENGHT"] = 5] = "__LENGHT";
    })(UserStatus = EUserEnum.UserStatus || (EUserEnum.UserStatus = {}));
})(EUserEnum || (EUserEnum = {}));
export default EUserEnum;
