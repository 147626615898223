const state = {
    NewOrdersLength: null,
};
const getters = {
    getNewOrdersList(state) {
        return state.NewOrdersLength;
    },
};
const mutations = {
    setOrdersLength(state, payload) {
        state.NewOrdersLength = payload;
    },
};
const actions = {};
export default {
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
};
