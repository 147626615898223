import FilesList from "@/views/CMS/Files/FilesList.vue";
import FilesCreate from "@/views/CMS/Files/FilesCreate.vue";
import FilesEdit from "@/views/CMS/Files/FilesEdit.vue";
const routes = [
    {
        path: "/secure/cms/files",
        name: "FilesList",
        component: FilesList,
    },
    {
        path: "/secure/cms/files/create",
        name: "FilesCreate",
        component: FilesCreate,
    },
    {
        path: "/secure/cms/files/edit/:file_uuid",
        name: "FilesEdit",
        component: FilesEdit,
    },
];
export default routes;
