import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import sweetalert from "sweetalert";
import ApiEnter from "@/api/ApiEnter";
import ApiFaq from "@/api/ApiFaq";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
import LeftMenuTab from "@/components/LeftMenu/LeftMenuTab.vue";
import PageHeader from "@/components/UI/PageHeader.vue";
import DataFaq from "@/data/AdminPanel/DataFaq";
import dayjs from "dayjs";
import { Getter } from "vuex-class";
import EUserEnum from "@/struct/user/EUserEnum";
let FaqEdit = class FaqEdit extends Vue {
    constructor() {
        super(...arguments);
        this.Breadcrumbs = DataFaq.FaqCategoriesBreadcrumbs;
        this.TableHeaders = DataFaq.CategoriesTableHeaders;
        this.TableItems = [];
        this.isOpenDialog = false;
        this.isOpenEditDialog = false;
        this.newCategory = {
            name: "",
            priority: "",
            publish: true,
            created: "",
        };
        this.currentCategory = {
            name: "",
            priority: "",
            publish: true,
            created: "",
            uuid: "",
        };
    }
    CanAddAndEditAccess() {
        return (this.GetCurrentSessionUser != undefined &&
            (this.GetCurrentSessionUser.system_role == EUserEnum.SystemRole.Admin ||
                this.GetCurrentSessionUser.system_role ==
                    EUserEnum.SystemRole.SuperAdmin ||
                this.GetCurrentSessionUser.system_role == EUserEnum.SystemRole.Editor));
    }
    CanDeleteAccess() {
        return (this.GetCurrentSessionUser != undefined &&
            (this.GetCurrentSessionUser.system_role == EUserEnum.SystemRole.Admin ||
                this.GetCurrentSessionUser.system_role ==
                    EUserEnum.SystemRole.SuperAdmin));
    }
    OpenDialog() {
        this.isOpenDialog = !this.isOpenDialog;
        this.newCategory.name = "";
        this.newCategory.priority = "";
        this.newCategory.publish = true;
        this.newCategory.created = "";
    }
    OpenEditDialog() {
        this.isOpenEditDialog = !this.isOpenEditDialog;
    }
    async CreateFaqCategory() {
        if (ApiEnter.CurrentSessionUUID != undefined) {
            this.$forceUpdate();
            const faq_uuid = await ApiFaq.CreateFaqCategory(ApiEnter.CurrentSessionUUID, this.newCategory.name, this.newCategory.priority, this.newCategory.publish ? 1 : 0);
            if (faq_uuid == undefined || faq_uuid.length != 36) {
                await sweetalert({
                    title: "Ошибка запроса!",
                    text: "Ошибка создания FAQ: " + faq_uuid,
                    icon: "info",
                });
                return;
            }
            this.$forceUpdate();
            sweetalert({
                title: this.$t("general.success"),
                text: this.$t("general.success_created"),
                icon: "success",
            }).then(() => this.OpenDialog());
            await this.GetFaqCategories();
        }
    }
    async ChangeFaqCategoryPublish(item) {
        try {
            const response = await ApiFaq.UpdateFaqCategoryPublish(item.publish ? 1 : 0, ApiEnter.CurrentSessionUUID, item.uuid);
            if (typeof response == "boolean") {
                sweetalert({
                    title: this.$t("general.success"),
                    text: this.$t("general.success_updated"),
                    icon: "success",
                }).then(() => {
                    this.GetFaqCategories();
                });
            }
            else {
                sweetalert({
                    title: this.$t("general.error"),
                    text: this.$t("general.request_error"),
                    icon: "info",
                });
            }
        }
        catch (e) {
            console.error(e);
            sweetalert({
                title: this.$t("general.error"),
                text: this.$t("general.request_error"),
                icon: "info",
            });
        }
    }
    async ChangeFaqCategoryInfo() {
        try {
            const response = await ApiFaq.UpdateFaqCategoryInfo(this.currentCategory.name, this.currentCategory.priority, this.currentCategory.publish ? 1 : 0, ApiEnter.CurrentSessionUUID, this.currentCategory.uuid);
            if (typeof response == "boolean") {
                sweetalert({
                    title: this.$t("general.success"),
                    text: this.$t("general.success_updated"),
                    icon: "success",
                }).then(() => {
                    this.OpenEditDialog();
                    this.GetFaqCategories();
                });
            }
            else {
                sweetalert({
                    title: this.$t("general.error"),
                    text: this.$t("general.request_error"),
                    icon: "info",
                });
            }
        }
        catch (e) {
            console.error(e);
            sweetalert({
                title: this.$t("general.error"),
                text: this.$t("general.request_error"),
                icon: "info",
            });
        }
    }
    async openEditDialog(uuid) {
        this.currentCategory.uuid = uuid;
        const categoryInfo = await ApiFaq.GetFaqCategoryByUUID(ApiEnter.CurrentSessionUUID, uuid);
        if (categoryInfo == undefined) {
            await sweetalert({
                title: this.$t("general.error"),
                text: this.$t("general.request_error"),
                icon: "info",
            });
            return;
        }
        this.OpenEditDialog();
        this.currentCategory.name = categoryInfo.name;
        this.currentCategory.priority = categoryInfo.priority;
        this.currentCategory.publish = categoryInfo.publish;
        this.currentCategory.created = dayjs(categoryInfo.create_at).format("DD.MM.YYYY");
    }
    DeleteCategory(faq_uuid) {
        sweetalert({
            title: this.$t("general.confirm"),
            text: this.$t("files.accept_delete"),
            icon: "warning",
            buttons: [
                this.$t("general.no"),
                this.$t("general.yes"),
            ],
        }).then(async (isConfirm) => {
            if (isConfirm == true) {
                const response = await ApiFaq.DeleteFaqCategory(ApiEnter.CurrentSessionUUID, faq_uuid);
                if (typeof response == "boolean") {
                    await sweetalert({
                        title: this.$t("general.success"),
                        text: this.$t("general.success_deleted"),
                        icon: "success",
                    });
                    await this.GetFaqCategories();
                }
                else {
                    await sweetalert({
                        title: this.$t("general.error"),
                        text: this.$t("general.request_error"),
                        icon: "info",
                    });
                }
            }
        });
    }
    async GetFaqCategories() {
        try {
            this.TableItems = await ApiFaq.GetFaqCategories(ApiEnter.CurrentSessionUUID);
        }
        catch (e) {
            console.error(e);
        }
    }
    created() {
        this.GetFaqCategories();
    }
};
__decorate([
    Getter("getCurrentSessionUser")
], FaqEdit.prototype, "GetCurrentSessionUser", void 0);
FaqEdit = __decorate([
    Component({
        components: { PageHeader, StandartTemplate, LeftMenuTab },
    })
], FaqEdit);
export default FaqEdit;
