import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import PageHeader from "@/components/UI/PageHeader.vue";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
import DataOffers from "@/data/AdminPanel/DataOffers";
import ApiEnter from "@/api/ApiEnter";
import ApiOffer from "@/api/ApiOffer";
import sweetalert from "sweetalert";
import ApiAdmin from "@/api/ApiAdmin";
import UtilGeneral from "@/util/Common/UtilGeneral";
import { EIncludeTariffs } from "@/struct/offers/EIncludeTariffs";
let OfferEdit = class OfferEdit extends Vue {
    constructor() {
        super(...arguments);
        this.Breadcrumbs = DataOffers.OfferEditBreadcrumbs;
        this.OffersCategories = [];
        this.emsOffers = [];
        this.selectOffer = null;
        this.emsOffer = null;
        this.newOffer = {
            name: "",
            priority: "",
            publish: true,
            category: [],
            price: "",
            renewalPrice: "",
            data: "",
            sms: "",
            min: "",
            additional_data: "",
            additional_price_info: "",
            sticker_text: "",
            overview: "",
            note: "",
            display_offers: true,
            display_home: false,
            display_for_new: false,
            display_for_current: false,
            tariff_overview: "",
            prospects_info: null,
            prospects_info_name: "",
            title: "",
            description: "",
            additional_description: "",
            selectTariff: null,
            keywords: "",
            url: "",
        };
        this.HasLoading = false;
        this.tariffItems = [
            {
                text: "Нет",
                value: null,
            },
            {
                text: "Wink",
                value: EIncludeTariffs.Wink,
            },
            {
                text: "Wink+",
                value: EIncludeTariffs.WinkPlus,
            },
        ];
    }
    async getOfferInfo() {
        const offerInfo = await ApiOffer.GetOfferByUUID(ApiEnter.CurrentSessionUUID, this.CurrentOfferUUID);
        if (offerInfo == undefined) {
            sweetalert({
                title: this.$t("general.error"),
                text: this.$t("general.request_error"),
                icon: "info",
            }).then(() => {
                this.$router.go(-1);
            });
            return;
        }
        this.newOffer.name = offerInfo.name;
        this.newOffer.priority = offerInfo.priority;
        this.newOffer.publish = offerInfo.publish;
        this.newOffer.price = String(offerInfo.price);
        this.newOffer.renewalPrice = String(offerInfo.renewal_price);
        this.newOffer.data = offerInfo.data;
        this.newOffer.sms = offerInfo.sms;
        this.newOffer.min = offerInfo.min;
        this.newOffer.additional_data = offerInfo.additional_data;
        this.newOffer.additional_description = offerInfo.additional_description;
        this.newOffer.additional_price_info = offerInfo.additional_price_info;
        this.newOffer.sticker_text = offerInfo.sticker_text;
        this.newOffer.overview = offerInfo.overview;
        this.newOffer.note = offerInfo.note;
        this.newOffer.design = offerInfo.design;
        this.newOffer.display_offers = offerInfo.display_offers;
        this.newOffer.display_home = offerInfo.display_home;
        this.newOffer.display_for_new = offerInfo.display_for_new;
        this.newOffer.display_for_current = offerInfo.display_for_current;
        this.newOffer.prospects_info = offerInfo.prospects_info
            ? ApiAdmin.GetFiles(ApiEnter.CurrentSessionUUID, offerInfo.prospects_info).then((response) => {
                this.newOffer.prospects_info = new File([new Blob([response.data])], offerInfo.prospects_info.split("/")[8]);
            })
            : null;
        this.newOffer.prospects_info_name = offerInfo.prospects_info;
        if (offerInfo.ems_product_name != null &&
            offerInfo.ems_product_id != null) {
            this.emsOffer = {
                name: offerInfo.ems_product_name,
                id: offerInfo.ems_product_id,
            };
            this.selectOffer = offerInfo.ems_product_id;
        }
        this.newOffer.title = offerInfo.title;
        this.newOffer.description = offerInfo.description;
        this.newOffer.keywords = offerInfo.keywords;
        this.newOffer.url = offerInfo.url;
        const service = JSON.parse(offerInfo.service);
        this.newOffer.selectTariff = service?.value || null;
    }
    filterOffer() {
        this.emsOffer = this.emsOffers.find((item) => item.id === this.selectOffer);
    }
    async OnClickSubmit() {
        this.HasLoading = true;
        try {
            let service = this.tariffItems.find((item) => item.value == this.newOffer.selectTariff);
            if (service.value == null) {
                service = null;
            }
            const response = await ApiOffer.UpdateOfferInfo(this.newOffer.name, this.newOffer.priority, this.newOffer.publish ? 1 : 0, this.newOffer.price, this.newOffer.data, this.newOffer.sms, this.newOffer.min, this.newOffer.additional_data, this.newOffer.additional_price_info, this.newOffer.sticker_text, this.newOffer.overview, this.newOffer.note, this.newOffer.display_offers ? 1 : 0, this.newOffer.display_home ? 1 : 0, this.newOffer.display_for_new ? 1 : 0, this.newOffer.display_for_current ? 1 : 0, this.newOffer.tariff_overview, this.newOffer.prospects_info != null
                ? (await ApiAdmin.UploadFile(ApiEnter.CurrentSessionUUID, this.newOffer.prospects_info, this.newOffer.prospects_info_name))
                : "", this.newOffer.title, this.newOffer.description, this.newOffer.additional_description, this.emsOffer ? this.emsOffer?.id : null, this.emsOffer ? this.emsOffer?.name : null, this.newOffer.keywords, this.newOffer.url, this.newOffer.renewalPrice, JSON.stringify(service), ApiEnter.CurrentSessionUUID, this.CurrentOfferUUID);
            if (typeof response == "boolean") {
                sweetalert({
                    title: this.$t("general.success"),
                    text: this.$t("general.success_updated"),
                    icon: "success",
                }).then(() => {
                    this.$router.go(-1);
                });
            }
            else {
                await sweetalert({
                    title: this.$t("general.error"),
                    text: this.$t("general.request_error"),
                    icon: "info",
                });
            }
        }
        catch (e) {
            console.error(e);
            await sweetalert({
                title: this.$t("general.error"),
                text: this.$t("general.request_error"),
                icon: "info",
            });
        }
        this.HasLoading = false;
    }
    CopyLink(link) {
        UtilGeneral.CopyLink(link);
    }
    Translit(str) {
        let ru = "А-а-Б-б-В-в-Ґ-ґ-Г-г-Д-д-Е-е-Ё-ё-Є-є-Ж-ж-З-з-И-и-І-і-Ї-ї-Й-й-К-к-Л-л-М-м-Н-н-О-о-П-п-Р-р-С-с-Т-т-У-у-Ф-ф-Х-х-Ц-ц-Ч-ч-Ш-ш-Щ-щ-Ъ-ъ-Ы-ы-Ь-ь-Э-э-Ю-ю-Я-я".split("-");
        let en = "A-a-B-b-V-v-G-g-G-g-D-d-E-e-E-e-E-e-ZH-zh-Z-z-I-i-I-i-I-i-J-j-K-k-L-l-M-m-N-n-O-o-P-p-R-r-S-s-T-t-U-u-F-f-H-h-TS-ts-CH-ch-SH-sh-SCH-sch-'-'-Y-y-'-'-E-e-YU-yu-YA-ya".split("-");
        let res = "";
        for (let i = 0, l = str.length; i < l; i++) {
            let s = str.charAt(i), n = ru.indexOf(s);
            if (n >= 0) {
                res += en[n];
            }
            else {
                res += s;
            }
        }
        return res;
    }
    ValidateSeoUrl() {
        this.newOffer.url = this.Translit(this.newOffer.url.replace(/[. ,$@!^()'*]+/g, "-").toLowerCase());
    }
    async DownloadProspectsInfo() {
        await ApiAdmin.GetFiles(ApiEnter.CurrentSessionUUID, this.newOffer.prospects_info_name).then((response) => {
            let a = document.createElement("a");
            let file = new Blob([response]);
            a.href = URL.createObjectURL(file);
            a.download = this.newOffer.prospects_info_name.split("/")[8];
            a.click();
        });
    }
    async getCMSOffers() {
        try {
            const response = await ApiOffer.GetEMSOffers(ApiEnter.CurrentSessionUUID, "main");
            if (typeof response != "boolean") {
                this.emsOffers = response;
            }
        }
        catch (e) {
            console.error(e);
        }
    }
    created() {
        this.CurrentOfferUUID = this.$route.params.offer_uuid;
        this.getCMSOffers();
        this.getOfferInfo();
    }
};
OfferEdit = __decorate([
    Component({
        components: { StandartTemplate, PageHeader },
    })
], OfferEdit);
export default OfferEdit;
