import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import PageHeader from "@/components/UI/PageHeader.vue";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
import ApiEnter from "@/api/ApiEnter";
import ApiShopOptions from "@/api/ApiShopOptions";
import sweetalert from "sweetalert";
import DataOptions from "@/data/AdminPanel/DataOptions";
import ApiOffer from "@/api/ApiOffer";
let OptionsCreate = class OptionsCreate extends Vue {
    constructor() {
        super(...arguments);
        this.Breadcrumbs = DataOptions.BreadcrumbsCreate;
        this.emsOffers = [];
        this.selectOffer = null;
        this.emsOffer = null;
        this.newOption = {
            name: "",
            name_for_mobile: "",
            description: "",
            priority: "",
            blockOneTitle: "",
            blockOneBody: "",
            blockTwoTitle: "",
            blockTwoBody: "",
            publish: true,
            roaming: true,
            type: "extra",
            available_to_activate: false,
            available_to_deactivate: false,
        };
        this.HasLoading = false;
    }
    filterOffer() {
        this.emsOffer = this.emsOffers.find((item) => item.id === this.selectOffer);
    }
    async OnClickSubmit() {
        this.HasLoading = true;
        if (ApiEnter.CurrentSessionUUID != undefined) {
            this.$forceUpdate();
            const option_uuid = await ApiShopOptions.CreateOption(ApiEnter.CurrentSessionUUID, this.newOption.name, this.newOption.name_for_mobile, this.newOption.priority, this.newOption.publish ? 1 : 0, this.newOption.type, this.emsOffer ? this.emsOffer?.name : null, this.emsOffer ? this.emsOffer?.id : null, this.newOption.description, this.newOption.blockOneTitle, this.newOption.blockOneBody, this.newOption.blockTwoTitle, this.newOption.blockTwoBody, this.newOption.roaming ? 1 : 0, this.newOption.available_to_activate ? 1 : 0, this.newOption.available_to_deactivate ? 1 : 0);
            if (option_uuid == undefined || option_uuid.length != 36) {
                await sweetalert({
                    title: this.$t("general.error"),
                    text: this.$t("general.request_error"),
                    icon: "info",
                });
                this.HasLoading = false;
                return;
            }
            sweetalert({
                title: this.$t("general.success"),
                text: this.$t("general.success_created"),
                icon: "success",
            }).then(() => {
                this.$forceUpdate();
                this.$router.push({ name: "OptionsList" });
            });
        }
        this.HasLoading = false;
    }
    async getCMSOffers() {
        try {
            const response = await ApiOffer.GetEMSOffers(ApiEnter.CurrentSessionUUID, "additional");
            if (typeof response != "boolean") {
                this.emsOffers = response;
            }
        }
        catch (e) {
            console.error(e);
        }
    }
    created() {
        this.getCMSOffers();
    }
};
OptionsCreate = __decorate([
    Component({
        components: { StandartTemplate, PageHeader },
    })
], OptionsCreate);
export default OptionsCreate;
