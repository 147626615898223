import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import PageHeader from "@/components/UI/PageHeader.vue";
import DataUser from "@/data/AdminPanel/DataUser";
import ApiAdmin from "@/api/ApiAdmin";
import ApiEnter from "@/api/ApiEnter";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
import EUserEnum from "@/struct/user/EUserEnum";
let Users = class Users extends Vue {
    constructor() {
        super(...arguments);
        this.Breadcrumbs = DataUser.Breadcrumbs;
        this.TableHeaders = DataUser.TableHeaders;
        this.TableItems = [];
    }
    async getUsers() {
        try {
            this.TableItems = await ApiAdmin.GetUsers(ApiEnter.CurrentSessionUUID);
        }
        catch (e) {
            console.error(e);
        }
    }
    ValidateUserStatus(status) {
        if (status == EUserEnum.UserStatus.Active) {
            return "Активный";
        }
        if (status == EUserEnum.UserStatus.NoActive) {
            return "Не активный";
        }
    }
    ValidateUserSystemRole(role) {
        if (role == EUserEnum.SystemRole.Courier) {
            return "Куръерская служба";
        }
        if (role == EUserEnum.SystemRole.Reader) {
            return "Читатель";
        }
        if (role == EUserEnum.SystemRole.Editor) {
            return "Редактор";
        }
        if (role == EUserEnum.SystemRole.Admin) {
            return "Администратор";
        }
        if (role == EUserEnum.SystemRole.SuperAdmin) {
            return "Супер Администратор";
        }
    }
    EditUser(uuid) {
        this.$router.push(`/secure/admin/edit-user/${uuid}`);
    }
    mounted() {
        this.getUsers();
    }
};
Users = __decorate([
    Component({
        components: { StandartTemplate, PageHeader },
    })
], Users);
export default Users;
