import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import EnterTemplate from "@/components/Template/EnterTemplate.vue";
import InputTextFromEnter from "@/components/Template/Enter/InputTextFromEnter.vue";
import InputEnterEmail from "@/components/Enter/InputEnterEmail.vue";
import InputEnterPassword from "@/components/Enter/InputEnterPassword.vue";
import sweetalert from "sweetalert";
import ApiEnter from "@/api/ApiEnter";
let Registration = class Registration extends Vue {
    constructor() {
        super(...arguments);
        this.Form = {
            Email: {
                value: "",
                empty: true,
                focused: false,
                valid: false,
            },
            Password: {
                value: "",
                empty: true,
                focused: false,
                valid: false,
            },
            Password2: {
                value: "",
                empty: true,
                focused: false,
                valid: false,
            },
        };
    }
    created() {
        document.title = "Registration";
        this.HasValidForm = false;
        this.HasRegistrationRequest = false;
    }
    GetClass_FormRow(model) {
        let result = " form-row";
        result += model.focused ? " focused " : "";
        result += !model.empty && !model.valid ? " tooltip-show " : "";
        return result;
    }
    GetClass_FormRowInput(model) {
        let result = " form-control";
        if (model.empty == false) {
            result += model.valid ? " is-valid" : "";
            result += !model.valid && !model.empty ? " is-invalid" : "";
        }
        return result;
    }
    OnInputFocusIn(model) {
        model.focused = true;
        this.$forceUpdate();
    }
    OnInputFocusOut(model) {
        model.focused = false;
        this.$forceUpdate();
    }
    SetInputValue(model, line) {
        model.value = line;
        this.OnInputChanged(model);
    }
    OnInputChanged(model) {
        let newValidState = false;
        model.empty = model.value.length == 0;
        if (model == this.Form.Email) {
            newValidState =
                model.value
                    .toLowerCase()
                    .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) != null;
        }
        else if (model == this.Form.Password) {
            newValidState = model.value.length >= 6 && model.value.length <= 32;
        }
        else if (model == this.Form.Password2) {
            newValidState = model.value == this.Form.Password.value;
        }
        if (newValidState != model.valid) {
            model.valid = newValidState;
            this.HasValidForm =
                this.Form.Email.valid == true &&
                    this.Form.Password.valid == true &&
                    this.Form.Password2.valid == true;
        }
        this.$forceUpdate();
    }
    OnSubmit() {
        if (this.HasValidForm == false) {
            sweetalert({
                title: this.$t("general.error"),
                icon: "error",
            });
            return;
        }
        const email = this.Form.Email.value;
        const password = this.Form.Password.value;
        this.HasRegistrationRequest = true;
        this.SetInputValue(this.Form.Password, "");
        this.SetInputValue(this.Form.Password2, "");
        ApiEnter.Registration(email, password)
            .then((user_uuid) => {
            sweetalert({
                title: this.$t("general.success"),
                text: this.$t("enter.success_registration_title") + email,
                icon: "success",
            }).then(() => {
                this.$router.push(`/secure/enter/login/${email}?user_uuid=${user_uuid}`);
            });
        })
            .catch((err) => {
            if ("response" in err == false ||
                (err?.response?.status || 599) >= 500) {
                sweetalert({
                    title: this.$t("general.error"),
                    text: this.$t("general.request_error"),
                    icon: "error",
                });
            }
            else {
                sweetalert({
                    title: this.$t("general.error"),
                    text: this.$t("general.request_error"),
                    icon: "error",
                });
            }
        })
            .finally(() => {
            this.HasRegistrationRequest = false;
            this.$forceUpdate();
        });
    }
};
Registration = __decorate([
    Component({
        components: {
            InputEnterPassword,
            InputEnterEmail,
            InputTextFromEnter,
            EnterTemplate,
        },
    })
], Registration);
export default Registration;
