import { __decorate } from "tslib";
import { Component, Prop, Vue } from "vue-property-decorator";
import InputTextFromEnter from "@/components/Template/Enter/InputTextFromEnter.vue";
let InputEnterEmail = class InputEnterEmail extends Vue {
    created() {
        this.privateValueField = this.value;
        this.hasValueValidStatus = false;
        this.hasValueFocusStatus = false;
    }
    OnFocusIn() {
        this.hasValueFocusStatus = true;
        this.$forceUpdate();
    }
    OnFocusOut() {
        this.hasValueFocusStatus = false;
        this.$forceUpdate();
    }
    OnInputEvent() {
        this.$emit("input", this.privateValueField);
        this.DoValidate();
    }
    DoValidate() {
        const newValidState = this.privateValueField
            .toLowerCase()
            .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) != null;
        if (newValidState != this.hasValueValidStatus) {
            this.$emit("valid", newValidState);
        }
        this.hasValueValidStatus = newValidState;
        this.ForceUpdate();
    }
    ForceUpdate() {
        this.$forceUpdate();
        for (const childItem of this.$children) {
            childItem.$forceUpdate();
        }
    }
};
__decorate([
    Prop({ default: "" })
], InputEnterEmail.prototype, "value", void 0);
__decorate([
    Prop({ default: false })
], InputEnterEmail.prototype, "readonly", void 0);
InputEnterEmail = __decorate([
    Component({
        components: { InputTextFromEnter },
    })
], InputEnterEmail);
export default InputEnterEmail;
