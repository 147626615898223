import { __decorate } from "tslib";
import { Component, Vue, Ref } from "vue-property-decorator";
import { Getter } from "vuex-class";
import sweetalert from "sweetalert";
import ApiEnter from "@/api/ApiEnter";
import DataEditUser from "@/data/AdminPanel/DataEditUser";
import ApiAdmin from "@/api/ApiAdmin";
import FormGenerator from "@/components/UI/Form/FormGenerator.vue";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
import PageHeader from "@/components/UI/PageHeader.vue";
let ProfileEdit = class ProfileEdit extends Vue {
    constructor() {
        super(...arguments);
        this.Breadcrumbs = DataEditUser.Breadcrumbs;
        this.LeftMenuItems = DataEditUser.LeftMenuItems;
        this.CurrentLeftMenuItem = DataEditUser.DefaultKeyFromLeftMenu;
        this.UserInfoModel = DataEditUser.FormUserData;
        this.UserPasswordModel = DataEditUser.FormUserPassword;
        this.UserEmailModel = DataEditUser.FormUserEmail;
        this.UserChangeStatus = DataEditUser.FormChangeStatus;
        this.UserChangeRole = DataEditUser.FormChangeRole;
    }
    async LoadUserData() {
        this.CurrentUserUUID = this.$route.params.user_uuid;
        this.UserData = await ApiAdmin.GetUser(ApiEnter.CurrentSessionUUID, this.CurrentUserUUID);
        if (this.UserData) {
            this.UserInfoModel.Name.value = this.UserData.first_name;
            this.UserInfoModel.LastName.value = this.UserData.last_name;
            this.UserInfoModel.Phone.value = this.UserData.phone;
            this.UserEmailModel.Email.value = this.UserData.email;
            this.UserChangeStatus.Status.value = this.UserData.status;
            this.UserChangeRole.Role.value = this.UserData.system_role;
        }
    }
    created() {
        DataEditUser.ClearFormModel();
        this.LoadUserData();
    }
    OnClickLeftMenuItem(item) {
        if (item.key == "delete") {
            this.OnClickDeleteFromLeftMenuItem();
        }
        else {
            this.CurrentLeftMenuItem = item.key;
        }
    }
    OnClickDeleteFromLeftMenuItem() {
        sweetalert({
            title: this.$t("general.confirm"),
            text: this.$t("user_management.confirm_delete"),
            icon: "warning",
            buttons: [
                this.$t("general.no"),
                this.$t("general.yes"),
            ],
        }).then(async (isConfirm) => {
            if (isConfirm == true) {
                const response = await ApiAdmin.DeleteUser(ApiEnter.CurrentSessionUUID, this.CurrentUserUUID);
                if (typeof response == "boolean") {
                    sweetalert({
                        title: this.$t("general.success"),
                        text: this.$t("general.success_deleted"),
                        icon: "success",
                    });
                    this.$router.replace("/");
                }
                else {
                    sweetalert({
                        title: this.$t("general.error"),
                        text: this.$t("general.request_error"),
                        icon: "info",
                    });
                }
            }
        });
    }
    async OnClickChangePassword() {
        const validate = this.UserPasswordGenerator.CurrentForm.validate();
        if (!validate) {
            sweetalert({
                title: this.$t("general.error"),
                text: this.$t("general.request_error"),
                icon: "info",
            });
            return;
        }
        try {
            const response = await ApiAdmin.UpdatePassword(this.UserPasswordModel.Password.value, this.CurrentUserUUID, ApiEnter.CurrentSessionUUID);
            if (typeof response == "boolean") {
                sweetalert({
                    title: this.$t("general.success"),
                    text: this.$t("general.success_updated"),
                    icon: "success",
                });
            }
            else {
                sweetalert({
                    title: this.$t("general.error"),
                    text: this.$t("general.request_error"),
                    icon: "info",
                });
            }
        }
        catch (e) {
            console.error(e);
            sweetalert({
                title: this.$t("general.error"),
                text: this.$t("general.request_error"),
                icon: "info",
            });
        }
    }
    async OnClickChangeEmail() {
        const validate = this.UserEmailGenerator.CurrentForm.validate();
        if (!validate) {
            sweetalert({
                title: this.$t("general.error"),
                text: this.$t("general.request_error"),
                icon: "info",
            });
            return;
        }
        try {
            const response = await ApiAdmin.UpdateEmail(this.UserEmailModel.Email.value, this.CurrentUserUUID, ApiEnter.CurrentSessionUUID);
            if (typeof response == "boolean") {
                sweetalert({
                    title: this.$t("general.success"),
                    text: this.$t("general.success_updated"),
                    icon: "success",
                });
            }
            else {
                sweetalert({
                    title: this.$t("general.error"),
                    text: this.$t("general.request_error"),
                    icon: "info",
                });
            }
        }
        catch (e) {
            console.error(e);
            sweetalert({
                title: this.$t("general.error"),
                text: this.$t("general.request_error"),
                icon: "info",
            });
        }
    }
    async OnClickChangeRole() {
        try {
            const response = await ApiAdmin.UpdateUserRole(this.CurrentUserUUID, ApiEnter.CurrentSessionUUID, this.UserChangeRole.Role.value);
            if (typeof response == "boolean") {
                sweetalert({
                    title: this.$t("general.success"),
                    text: this.$t("general.success_updated"),
                    icon: "success",
                });
            }
            else {
                sweetalert({
                    title: this.$t("general.error"),
                    text: this.$t("general.request_error"),
                    icon: "info",
                });
            }
        }
        catch (e) {
            console.error(e);
            sweetalert({
                title: this.$t("general.error"),
                text: this.$t("general.request_error"),
                icon: "info",
            });
        }
    }
    async OnClickChangeStatus() {
        try {
            const response = await ApiAdmin.UpdateUserStatus(this.CurrentUserUUID, ApiEnter.CurrentSessionUUID, this.UserChangeStatus.Status.value);
            if (typeof response == "boolean") {
                sweetalert({
                    title: this.$t("general.success"),
                    text: this.$t("general.success_updated"),
                    icon: "success",
                });
            }
            else {
                sweetalert({
                    title: this.$t("general.error"),
                    text: this.$t("general.request_error"),
                    icon: "info",
                });
            }
        }
        catch (e) {
            console.error(e);
            sweetalert({
                title: this.$t("general.error"),
                text: this.$t("general.request_error"),
                icon: "info",
            });
        }
    }
    async OnClickChangeProfile() {
        const validate = this.UserFormGenerator.CurrentForm.validate();
        if (!validate) {
            sweetalert({
                title: this.$t("general.error"),
                text: this.$t("general.request_error"),
                icon: "info",
            });
            return;
        }
        try {
            const response = await ApiAdmin.UpdateUserData(this.UserInfoModel.Name.value, this.UserInfoModel.LastName.value, this.UserInfoModel.Phone.value, ApiEnter.CurrentSessionUUID, this.CurrentUserUUID);
            if (typeof response == "boolean") {
                sweetalert({
                    title: this.$t("general.success"),
                    text: this.$t("general.success_updated"),
                    icon: "success",
                });
            }
            else {
                sweetalert({
                    title: this.$t("general.error"),
                    text: this.$t("general.request_error"),
                    icon: "info",
                });
            }
        }
        catch (e) {
            sweetalert({
                title: this.$t("general.error"),
                text: this.$t("general.request_error"),
                icon: "info",
            });
        }
    }
};
__decorate([
    Getter("getCurrentSessionUser")
], ProfileEdit.prototype, "GetCurrentSessionUser", void 0);
__decorate([
    Ref("validate-user-info")
], ProfileEdit.prototype, "UserFormGenerator", void 0);
__decorate([
    Ref("validate-password")
], ProfileEdit.prototype, "UserPasswordGenerator", void 0);
__decorate([
    Ref("validate-email")
], ProfileEdit.prototype, "UserEmailGenerator", void 0);
ProfileEdit = __decorate([
    Component({
        components: { PageHeader, StandartTemplate, FormGenerator },
    })
], ProfileEdit);
export default ProfileEdit;
