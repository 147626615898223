import i18n from "@/i18n";
export default class DataEmailNotifications {
}
DataEmailNotifications.Breadcrumbs = [
    {
        to: "/",
        text: i18n.t("breadcrumbs.home"),
    },
    {
        to: "/",
        text: i18n.t("breadcrumbs.settings"),
    },
    {
        text: i18n.t("breadcrumbs.email_notifications"),
        disabled: true,
    },
];
DataEmailNotifications.BreadcrumbsEdit = [
    {
        to: "/",
        text: i18n.t("breadcrumbs.home"),
    },
    {
        to: "/",
        text: i18n.t("breadcrumbs.settings"),
    },
    {
        to: "/secure/cms/settings/email-notifications",
        exact: true,
        text: i18n.t("breadcrumbs.email_notifications"),
    },
    {
        text: i18n.t("breadcrumbs.edit"),
        disabled: true,
    },
];
DataEmailNotifications.BreadcrumbsCreate = [
    {
        to: "/",
        text: i18n.t("breadcrumbs.home"),
    },
    {
        to: "/",
        text: i18n.t("breadcrumbs.settings"),
    },
    {
        to: "/secure/cms/settings/email-notifications",
        exact: true,
        text: i18n.t("breadcrumbs.email_notifications"),
    },
    {
        text: i18n.t("breadcrumbs.create"),
        disabled: true,
    },
];
DataEmailNotifications.TableHeaders = [
    {
        text: i18n.t("table_headers.updated"),
        value: "update",
    },
    {
        text: i18n.t("table_headers.template_name"),
        value: "template_name",
    },
    {
        text: i18n.t("table_headers.action"),
        value: "action",
    },
];
