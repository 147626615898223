import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import sweetalert from "sweetalert";
import ApiEnter from "@/api/ApiEnter";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
import PageHeader from "@/components/UI/PageHeader.vue";
import Editor from "@tinymce/tinymce-vue";
import ApiAdmin from "@/api/ApiAdmin";
import ApiSettings from "@/api/ApiSettings";
import DataEmailNotifications from "@/data/AdminPanel/DataEmailNotifications";
let EmailNotificationsCreate = class EmailNotificationsCreate extends Vue {
    constructor() {
        super(...arguments);
        this.Breadcrumbs = DataEmailNotifications.BreadcrumbsCreate;
        this.EmailNotification = {
            publish: true,
            template_id: "",
            template_name: "",
            topic_name: "",
            send_to: "",
            file: null,
            update: "",
        };
        this.HasLoading = false;
    }
    async OnClickSubmit() {
        this.HasLoading = true;
        if (ApiEnter.CurrentSessionUUID != undefined) {
            const email_notification_uuid = await ApiSettings.CreateEmailNotification(ApiEnter.CurrentSessionUUID, this.EmailNotification.publish ? 1 : 0, this.EmailNotification.template_id, this.EmailNotification.template_name, this.EmailNotification.topic_name, this.EmailNotification.send_to, this.EmailNotification.file != null
                ? (await ApiAdmin.UploadFile(ApiEnter.CurrentSessionUUID, this.EmailNotification.file))
                : "");
            if (email_notification_uuid == undefined ||
                email_notification_uuid.length != 36) {
                await sweetalert({
                    title: this.$t("general.error"),
                    text: this.$t("general.request_error"),
                    icon: "info",
                });
                return;
            }
            sweetalert({
                title: this.$t("general.success"),
                text: this.$t("general.success_created  "),
                icon: "success",
            }).then(() => {
                this.$forceUpdate();
                this.$router.push({ name: "EmailNotifications" });
            });
        }
        this.HasLoading = false;
    }
};
EmailNotificationsCreate = __decorate([
    Component({
        components: { PageHeader, StandartTemplate, Editor },
    })
], EmailNotificationsCreate);
export default EmailNotificationsCreate;
