import { __decorate } from "tslib";
import { Component, Ref, Vue } from "vue-property-decorator";
import sweetalert from "sweetalert";
import ApiEnter from "@/api/ApiEnter";
import { Getter, Mutation } from "vuex-class";
import PageHeader from "@/components/UI/PageHeader.vue";
import FormGenerator from "@/components/UI/Form/FormGenerator.vue";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
import DataEditCompany from "@/data/AdminPanel/DataEditCompany";
import ApiAdmin from "@/api/ApiAdmin";
import ApiProfile from "@/api/ApiProfile";
let CompanyEdit = class CompanyEdit extends Vue {
    constructor() {
        super(...arguments);
        this.CompanyInfoModel = DataEditCompany.CompanyInfoModel;
        this.ContactInfoModel = DataEditCompany.ContactInfoModel;
        this.FinanceInfoModel = DataEditCompany.FinanceInfoModel;
        this.StatusInfoModel = DataEditCompany.ChangeStatusInfoModel;
        this.LeftMenuItems = DataEditCompany.LeftMenuItems;
        this.CurrentLeftMenuItem = DataEditCompany.DefaultKeyFromLeftMenu;
    }
    created() {
        this.Breadcrumbs = DataEditCompany.Breadcrumbs;
        this.HasLoadedForm = false;
        this.HasRequest = false;
        this.CurrentCompanyUUID = this.$route.params.company_uuid;
        this.DoLoadForm();
    }
    async DoLoadForm() {
        const companyInfo = await ApiAdmin.GetCompanyByUUID(ApiEnter.CurrentSessionUUID, this.CurrentCompanyUUID);
        if (companyInfo == undefined) {
            sweetalert({
                title: "Упс!",
                text: "Во время загрузки информации - возникла ошибка, не все данные были заружены!",
                icon: "error",
            }).then(() => {
                this.$router.go(-1);
            });
            return;
        }
        this.SetInputValue(this.CompanyInfoModel.Name, this.GetCurrentCompanyItem?.name);
        this.SetInputValue(this.CompanyInfoModel.Address, companyInfo.address);
        this.SetInputValue(this.CompanyInfoModel.OfficalAddress, companyInfo.offical_addres);
        this.SetInputValue(this.CompanyInfoModel.OfficalName, companyInfo.offical_name);
        this.SetInputValue(this.CompanyInfoModel.Ogrn, companyInfo.offical_ogrn);
        this.SetInputValue(this.CompanyInfoModel.Inn, companyInfo.offical_inn);
        this.SetInputValue(this.ContactInfoModel.CeoFullName, companyInfo.ceo_full_name);
        this.SetInputValue(this.ContactInfoModel.CompanyEmail, companyInfo.company_email);
        this.SetInputValue(this.ContactInfoModel.CompanyPhone, companyInfo.company_phone);
        this.SetInputValue(this.FinanceInfoModel.BankRs, companyInfo.bank_rs);
        this.SetInputValue(this.FinanceInfoModel.BankKs, companyInfo.bank_ks);
        this.SetInputValue(this.FinanceInfoModel.Bik, companyInfo.bik);
        this.SetInputValue(this.FinanceInfoModel.BankName, companyInfo.bank_name);
        this.SetInputValue(this.StatusInfoModel.Status, companyInfo.status);
        this.HasLoadedForm = true;
        this.$forceUpdate();
    }
    mounted() {
        if (this.GetCurrentCompanyItem == undefined) {
            return;
        }
        document.title = "Редактирование компании...";
    }
    async OnClickSubmitCompanyInfo() {
        const validate = this.CompanyFormGenerator.CurrentForm.validate();
        if (!validate) {
            sweetalert({
                title: "Ошибка в форме!",
                text: "Вы заполнили не все поля или есть ошибки!",
                icon: "error",
            });
            return;
        }
        this.HasRequest = true;
        const response = await ApiAdmin.UpdateCompanyInfo(ApiEnter.CurrentSessionUUID, this.CurrentCompanyUUID, this.CompanyInfoModel.OfficalName.value, this.CompanyInfoModel.Inn.value, this.CompanyInfoModel.Ogrn.value, this.CompanyInfoModel.OfficalAddress.value, this.CompanyInfoModel.Address.value);
        if (typeof response == "boolean") {
            sweetalert({
                title: "Успех!",
                text: `Изменения успешно внесены!`,
                icon: "success",
            });
        }
        else {
            sweetalert({
                title: "Ошибка!",
                text: `Во время выполнения запроса, возникла ошибка: ${response}`,
                icon: "error",
            });
        }
        this.HasRequest = false;
    }
    async OnClickSubmitContactInfo() {
        const validate = this.ContactFormGenerator.CurrentForm.validate();
        if (!validate) {
            sweetalert({
                title: "Ошибка в форме!",
                text: "Вы заполнили не все поля или есть ошибки!",
                icon: "error",
            });
            return;
        }
        this.HasRequest = true;
        const response = await ApiAdmin.UpdateContactInfo(ApiEnter.CurrentSessionUUID, this.CurrentCompanyUUID, this.ContactInfoModel.CeoFullName.value, this.ContactInfoModel.CompanyEmail.value, this.ContactInfoModel.CompanyPhone.value);
        if (typeof response == "boolean") {
            sweetalert({
                title: "Успех!",
                text: `Изменения успешно внесены!`,
                icon: "success",
            });
        }
        else {
            sweetalert({
                title: "Ошибка!",
                text: `Во время выполнения запроса, возникла ошибка: ${response}`,
                icon: "error",
            });
        }
        this.HasRequest = false;
    }
    async OnClickSubmitFinanceInfo() {
        const validate = this.FinanceFormGenerator.CurrentForm.validate();
        if (!validate) {
            sweetalert({
                title: "Ошибка в форме!",
                text: "Вы заполнили не все поля или есть ошибки!",
                icon: "error",
            });
            return;
        }
        this.HasRequest = true;
        const response = await ApiAdmin.UpdateFinanceInfo(ApiEnter.CurrentSessionUUID, this.CurrentCompanyUUID, this.FinanceInfoModel.BankRs.value, this.FinanceInfoModel.Bik.value, this.FinanceInfoModel.BankKs.value, this.FinanceInfoModel.BankName.value);
        if (typeof response == "boolean") {
            sweetalert({
                title: "Успех!",
                text: `Изменения успешно внесены!`,
                icon: "success",
            });
        }
        else {
            sweetalert({
                title: "Ошибка!",
                text: `Во время выполнения запроса, возникла ошибка: ${response}`,
                icon: "error",
            });
        }
        this.HasRequest = false;
    }
    async OnClickSubmitStatus() {
        this.HasRequest = true;
        const response = await ApiAdmin.UpdateCompanyStatus(ApiEnter.CurrentSessionUUID, this.CurrentCompanyUUID, this.StatusInfoModel.Status.value);
        if (typeof response == "boolean") {
            sweetalert({
                title: "Успех!",
                text: `Изменения успешно внесены!`,
                icon: "success",
            });
        }
        else {
            sweetalert({
                title: "Ошибка!",
                text: `Во время выполнения запроса, возникла ошибка: ${response}`,
                icon: "error",
            });
        }
        this.HasRequest = false;
    }
    SetInputValue(model, line) {
        model.value = line;
    }
    OnClickLeftMenuItem(item) {
        if (item.key == "delete") {
            return this.OnClickDeleteFromLeftMenuItem();
        }
        if (item.key == "change-owner") {
            return this.OnClickChangeOwner();
        }
        this.CurrentLeftMenuItem = item.key;
    }
    OnClickDeleteFromLeftMenuItem() {
        sweetalert({
            title: "Вы уверены?",
            text: "Вы дейсвительно хотите удалить свой профиль?",
            icon: "warning",
            buttons: ["Нет, Отмена!", "Да, Подтверждаю!"],
        }).then(async (isConfirm) => {
            if (isConfirm == true) {
                const response = await ApiAdmin.DeleteCompany(ApiEnter.CurrentSessionUUID, this.CurrentCompanyUUID);
                if (typeof response == "boolean") {
                    sweetalert({
                        title: "Успешно!",
                        text: "Компания удалена",
                        icon: "success",
                    });
                    this.$router.replace("/secure/admin/companies");
                }
                else {
                    sweetalert({
                        title: "Ошибка!",
                        text: `Во время выполнения запроса, возникла ошибка: ${response}`,
                        icon: "error",
                    });
                }
            }
        });
    }
    OnClickChangeOwner() {
        sweetalert({
            title: "Добавление сотрудника",
            text: "Введите Email нового сотрудника:",
            content: {
                element: "input",
                attributes: {
                    placeholder: "Пример: vasya@mail.ru",
                    type: "email",
                },
            },
        }).then(async (result) => {
            if (result == null) {
                return;
            }
            if (result.trim().length == 0) {
                sweetalert({
                    title: "Ошибка!",
                    text: "Вы не чего не ввели, сотрудник не добавлен!",
                    icon: "error",
                });
                return;
            }
            if (!result.match(/^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                sweetalert({
                    title: "Ошибка!",
                    text: "Вы ввели не корректный Email!",
                    icon: "error",
                });
                return;
            }
            const user = await ApiProfile.GetUserFromEmail(ApiEnter.CurrentSessionUUID, result);
            if (user !== undefined) {
                const response = await ApiAdmin.UpdateCompanyOwner(ApiEnter.CurrentSessionUUID, this.CurrentCompanyUUID, user.user_uuid);
                if (typeof response == "boolean") {
                    sweetalert({
                        title: "Успех!",
                        text: `Изменения успешно внесены!`,
                        icon: "success",
                    });
                }
                else {
                    sweetalert({
                        title: "Ошибка!",
                        text: `Во время выполнения запроса, возникла ошибка: ${response}`,
                        icon: "error",
                    });
                }
            }
            else {
                sweetalert({
                    title: "Ошибка!",
                    text: "Во время запроса, возникла ошибка: " + user,
                    icon: "error",
                });
            }
        });
    }
};
__decorate([
    Getter("getCurrentCompanyItem")
], CompanyEdit.prototype, "GetCurrentCompanyItem", void 0);
__decorate([
    Mutation("setCurrentCompanyItem")
], CompanyEdit.prototype, "SetCurrentCompanyItem", void 0);
__decorate([
    Ref("company-form-generator")
], CompanyEdit.prototype, "CompanyFormGenerator", void 0);
__decorate([
    Ref("contact-form-generator")
], CompanyEdit.prototype, "ContactFormGenerator", void 0);
__decorate([
    Ref("finance-form-generator")
], CompanyEdit.prototype, "FinanceFormGenerator", void 0);
CompanyEdit = __decorate([
    Component({
        components: { StandartTemplate, PageHeader, FormGenerator },
    })
], CompanyEdit);
export default CompanyEdit;
