export default class DataUser {
}
DataUser.Breadcrumbs = [
    {
        to: "/",
        text: "Home",
    },
    {
        to: "/secure/admin/users",
        text: "Управление пользователями",
        disabled: true,
    },
];
DataUser.TableHeaders = [
    {
        text: "Имя",
        value: "first_name",
    },
    {
        text: "Фамилия",
        value: "last_name",
    },
    {
        text: "Роль",
        value: "system_role",
    },
    {
        text: "Статус",
        value: "status",
    },
    {
        text: "Email",
        value: "email",
    },
    {
        text: "Действие",
        value: "action",
    },
];
