import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import EnterTemplate from "@/components/Template/EnterTemplate.vue";
import InputTextFromEnter from "@/components/Template/Enter/InputTextFromEnter.vue";
import InputEnterEmail from "@/components/Enter/InputEnterEmail.vue";
import InputEnterPassword from "@/components/Enter/InputEnterPassword.vue";
import { Action } from "vuex-class";
let Logout = class Logout extends Vue {
    created() {
        document.title = "Выход";
        const IsUserLogin = localStorage.getItem("XSessionUUIDOLD");
        if (IsUserLogin) {
            this.DoUpdateCurrentSessionUUID(IsUserLogin);
            localStorage.removeItem("XSessionUUIDOLD");
        }
        else {
            this.DoUpdateCurrentSessionUUID(undefined);
        }
        this.$router.push({ name: "EnterLogin" });
    }
};
__decorate([
    Action("doUpdateCurrentSessionUUID")
], Logout.prototype, "DoUpdateCurrentSessionUUID", void 0);
Logout = __decorate([
    Component({
        components: {
            InputEnterPassword,
            InputEnterEmail,
            InputTextFromEnter,
            EnterTemplate,
        },
    })
], Logout);
export default Logout;
