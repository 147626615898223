import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import sweetalert from "sweetalert";
import ApiEnter from "@/api/ApiEnter";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
import PageHeader from "@/components/UI/PageHeader.vue";
import Editor from "@tinymce/tinymce-vue";
import ApiSupportFiles from "@/api/ApiSupportFiles";
import dayjs from "dayjs";
import ApiAdmin from "@/api/ApiAdmin";
import UtilGeneral from "@/util/Common/UtilGeneral";
let FilesEdit = class FilesEdit extends Vue {
    constructor() {
        super(...arguments);
        this.Breadcrumbs = [
            {
                to: "/",
                text: "Главная",
            },
            {
                to: "/secure/cms/files",
                exact: true,
                text: "CMS",
            },
            {
                to: "/secure/cms/files",
                exact: true,
                text: "Поддержка (Files)",
            },
            {
                text: "Файлы",
                disabled: true,
            },
        ];
        this.FaqCategories = [];
        this.currentFile = {
            name: "",
            description: "",
            publish: true,
            priority: "",
            file: null,
            file_name: null,
            created: "",
        };
        this.HasLoading = false;
    }
    async OnClickSubmit() {
        this.HasLoading = true;
        if (ApiEnter.CurrentSessionUUID != undefined) {
            this.$forceUpdate();
            const file_name = await ApiAdmin.UploadFile(ApiEnter.CurrentSessionUUID, this.currentFile.file, this.currentFile.file_name);
            if (file_name) {
                const response = await ApiSupportFiles.UpdateFileInfo(this.currentFile.name, this.currentFile.description, file_name, this.currentFile.priority, this.currentFile.publish ? 1 : 0, ApiEnter.CurrentSessionUUID, this.currentFileUUID);
                if (typeof response == "boolean") {
                    sweetalert({
                        title: this.$t("general.success"),
                        text: this.$t("general.success_created"),
                        icon: "success",
                    }).then(() => {
                        this.$forceUpdate();
                        this.currentFile.name = "";
                        this.currentFile.description = "";
                        this.currentFile.priority = "";
                        this.currentFile.file = "";
                        this.currentFile.publish = true;
                        this.currentFile.created = "";
                        this.$router.push({ name: "FilesList" });
                    });
                }
                else {
                    await sweetalert({
                        title: this.$t("general.error"),
                        text: this.$t("general.request_error"),
                        icon: "info",
                    });
                }
            }
        }
        this.HasLoading = false;
    }
    CopyLink(link) {
        UtilGeneral.CopyLink(link);
    }
    async GetFaqInfo() {
        const faqInfo = await ApiSupportFiles.GetFileByUUID(ApiEnter.CurrentSessionUUID, this.currentFileUUID);
        if (faqInfo == undefined) {
            sweetalert({
                title: this.$t("general.error"),
                text: this.$t("general.request_error"),
                icon: "info",
            }).then(() => {
                this.$router.push("/");
            });
            return;
        }
        this.currentFile.name = faqInfo.name;
        this.currentFile.description = faqInfo.description;
        this.currentFile.publish = faqInfo.publish;
        this.currentFile.priority = faqInfo.priority;
        this.currentFile.file_name = faqInfo.file;
        this.currentFile.created = dayjs(faqInfo.create_at).format("DD.MM.YYYY HH:mm");
        await this.GetFile(faqInfo.file);
    }
    async GetFile(file_name) {
        await ApiAdmin.GetFiles(ApiEnter.CurrentSessionUUID, file_name).then((response) => {
            this.currentFile.file = new File([new Blob([response.data])], file_name.split("/")[8]);
        });
    }
    async DownloadFile() {
        await ApiAdmin.GetFiles(ApiEnter.CurrentSessionUUID, this.currentFile.file_name).then((response) => {
            let a = document.createElement("a");
            let file = new Blob([response]);
            a.href = URL.createObjectURL(file);
            a.download = this.currentFile.file_name.split("/")[8];
            a.click();
        });
    }
    mounted() {
        this.currentFileUUID = this.$route.params.file_uuid;
        this.GetFaqInfo();
    }
};
FilesEdit = __decorate([
    Component({
        components: { PageHeader, StandartTemplate, Editor },
    })
], FilesEdit);
export default FilesEdit;
