import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
import LeftMenuTab from "@/components/LeftMenu/LeftMenuTab.vue";
import PageHeader from "@/components/UI/PageHeader.vue";
import DataOrder from "@/data/Shop/Order/DataOrder";
import ApiEnter from "@/api/ApiEnter";
import ApiOrders from "@/api/ApiOrders";
import dayjs from "dayjs";
import { EOrderEnum } from "@/struct/order/EOrderEnum";
import { ExportToCsv } from "export-to-csv";
import EUserEnum from "@/struct/user/EUserEnum";
import { Getter } from "vuex-class";
let Orders = class Orders extends Vue {
    constructor() {
        super(...arguments);
        this.IsApplyFilter = false;
        this.HasRequest = false;
        this.search = "";
        this.FilteredTableItems = [];
        this.FilterTable = {
            DateFrom: "",
            DateTo: "",
            TotalFrom: "",
            TotalTo: "",
            OrderStatus: [],
            TariffList: [],
            TypeStatus: [],
        };
        this.FilterTableAppend = {
            DateFrom: "",
            DateTo: "",
            TotalFrom: "",
            TotalTo: "",
            OrderStatus: [],
            TariffList: [],
            TypeStatus: [],
        };
        this.Breadcrumbs = DataOrder.Breadcrumbs;
        this.TableHeaders = [
            {
                text: "Дата создания",
                value: "create_at",
                filter: (value) => {
                    if (this.FilterTableAppend.DateFrom && this.FilterTableAppend.DateTo) {
                        return (dayjs(value).format("YYYY-MM-DD") >=
                            this.FilterTableAppend.DateFrom &&
                            dayjs(value).format("YYYY-MM-DD") <= this.FilterTableAppend.DateTo);
                    }
                    if (this.FilterTableAppend.DateFrom && !this.FilterTableAppend.DateTo) {
                        return (dayjs(value).format("YYYY-MM-DD") >= this.FilterTableAppend.DateFrom);
                    }
                    if (!this.FilterTableAppend.DateFrom && this.FilterTableAppend.DateTo) {
                        return (dayjs(value).format("YYYY-MM-DD") <= this.FilterTableAppend.DateTo);
                    }
                    return true;
                },
            },
            {
                text: "Номер заказа",
                value: "id",
            },
            {
                text: "Тариф",
                value: "offer_name",
                filter: (value) => {
                    if (this.FilterTableAppend.TariffList.length) {
                        const items = this.FilterTableAppend.TariffList.filter((item) => {
                            return item === value;
                        });
                        return !!items.length;
                    }
                    return true;
                },
            },
            {
                text: "Цена",
                value: "amount",
            },
            {
                text: "Тип заказа",
                value: "type",
                filter: (value) => {
                    if (this.FilterTableAppend.TypeStatus.length) {
                        const items = this.FilterTableAppend.TypeStatus.filter((item) => {
                            return item === value;
                        });
                        return !!items.length;
                    }
                    return true;
                },
            },
            {
                text: "Номер переноса",
                value: "transfer_phone",
            },
            {
                text: "Клиент",
                value: "full_name",
            },
            {
                text: "Номер телефона",
                value: "phone",
            },
            {
                text: "Email",
                value: "email",
            },
            {
                text: "Адрес доставки",
                value: "full_address",
            },
            {
                text: "Статус заказа",
                value: "status",
                filter: (value) => {
                    if (this.FilterTableAppend.OrderStatus.length) {
                        const items = this.FilterTableAppend.OrderStatus.filter((item) => {
                            return item === value;
                        });
                        return !!items.length;
                    }
                    return true;
                },
            },
        ];
        this.TableItems = []; // TODO добавить интерфейс
        this.OrderStatusFilterItems = [
            {
                text: "Новый",
                value: EOrderEnum.OrderStatus.New,
            },
            {
                text: "Не завершенный",
                value: EOrderEnum.OrderStatus.NotFinished,
            },
        ];
        this.TypesOrderFilterItems = [
            {
                text: "Новый",
                value: EOrderEnum.OrderType.New,
            },
            {
                text: "Перенос",
                value: EOrderEnum.OrderType.TransferPhone,
            },
        ];
        this.TariffFilterItems = [];
        this.dateFrom = null;
        this.dateTo = null;
        this.menuDateFrom = false;
        this.menuDateTo = false;
        this.isShowFilter = false;
    }
    created() {
        this.LoadOrders();
    }
    getFiltered(e) {
        this.FilteredTableItems = e;
    }
    SetFilter() {
        this.IsApplyFilter = true;
        this.FilterTableAppend.TotalFrom = this.FilterTable.TotalFrom;
        this.FilterTableAppend.TotalTo = this.FilterTable.TotalTo;
        this.FilterTableAppend.DateFrom = this.FilterTable.DateFrom;
        this.FilterTableAppend.DateTo = this.FilterTable.DateTo;
        this.FilterTableAppend.OrderStatus = this.FilterTable.OrderStatus;
        this.FilterTableAppend.TariffList = this.FilterTable.TariffList;
        this.FilterTableAppend.TypeStatus = this.FilterTable.TypeStatus;
    }
    ResetFilter() {
        this.IsApplyFilter = false;
        this.FilterTableAppend.DateFrom = "";
        this.FilterTableAppend.TotalFrom = "";
        this.FilterTableAppend.TotalTo = "";
        this.FilterTableAppend.DateTo = "";
        this.FilterTableAppend.OrderStatus = [];
        this.FilterTableAppend.TariffList = [];
        this.FilterTableAppend.TypeStatus = [];
    }
    async LoadOrders() {
        this.HasRequest = true;
        const response = await ApiOrders.GetOrdersList(ApiEnter.CurrentSessionUUID);
        if (typeof response != "boolean") {
            this.TableItems = response;
            for (const item of this.TableItems) {
                const tariff = this.TariffFilterItems.find((el) => el === item.offer_name);
                if (tariff == undefined) {
                    this.TariffFilterItems.push(item.offer_name);
                }
            }
        }
        this.HasRequest = false;
    }
    CanExportOrders() {
        return (this.GetCurrentSessionUser != undefined &&
            (this.GetCurrentSessionUser.system_role == EUserEnum.SystemRole.Admin ||
                this.GetCurrentSessionUser.system_role ==
                    EUserEnum.SystemRole.SuperAdmin ||
                this.GetCurrentSessionUser.system_role == EUserEnum.SystemRole.Editor ||
                this.GetCurrentSessionUser.system_role == EUserEnum.SystemRole.Courier));
    }
    ExportToCSV() {
        if (this.CanExportOrders() == false)
            return;
        const optionsCSV = {
            fieldSeparator: ",",
            quoteStrings: '"',
            decimalSeparator: ".",
            showLabels: true,
            showTitle: true,
            title: "Выгрузка заказов",
            useTextFile: false,
            useBom: true,
            headers: [
                "Дата создания",
                "Номер заказа",
                "Тариф",
                "Цена",
                "Тип заказа",
                "Номер переноса",
                "Клиент",
                "Номер телефона",
                "Email",
                "Адрес доставки",
                "Статус заказа",
            ],
        };
        const csvExporter = new ExportToCsv(optionsCSV);
        const csv_items = [];
        for (const item of this.FilteredTableItems) {
            csv_items.push({
                create_at: this.formatDate(item.create_at),
                id: item.id,
                offer_name: item.offer_name,
                amount: item.amount,
                type: this.ValidateOrderType(item.type, "text"),
                transfer_phone: item.transfer_phone,
                full_name: item.full_name,
                phone: item.phone,
                email: item.email,
                full_address: item.full_address,
                status: this.ValidateOrderStatus(item.status, "text"),
            });
        }
        csvExporter.generateCsv(csv_items);
    }
    ValidateOrderStatus(value, type) {
        const Enums = EOrderEnum.OrderStatus;
        if (type == "color") {
            if (value === Enums.NotFinished)
                return "grey darken-1 text-white";
            if (value === Enums.New)
                return "yellow darken-2 text-black";
        }
        if (type == "text") {
            if (value === Enums.NotFinished)
                return "Не завершенный";
            if (value === Enums.New)
                return "Новый";
        }
    }
    ValidateOrderType(value, type) {
        const Enums = EOrderEnum.OrderType;
        if (type == "color") {
            if (value === Enums.TransferPhone)
                return "grey darken-1 text-white";
            if (value === Enums.New)
                return "yellow darken-2 text-black";
        }
        if (type == "text") {
            if (value === Enums.TransferPhone)
                return "Перенос номера";
            if (value === Enums.New)
                return "Новый";
        }
        return "";
    }
    formatDate(item) {
        return dayjs(item).format("DD-MM-YYYY (HH:mm:s)");
    }
};
__decorate([
    Getter("getCurrentSessionUser")
], Orders.prototype, "GetCurrentSessionUser", void 0);
Orders = __decorate([
    Component({
        components: { PageHeader, StandartTemplate, LeftMenuTab },
    })
], Orders);
export default Orders;
