import axios from "axios";
class ApiCompany {
    static async CreateCompanyFromSession(session_uuid, name, offical_inn, offical_ogrn, offical_name) {
        try {
            const result = await axios.post((process.env.VUE_APP_API_PATH || "/") + "api/company/create", {
                name: name,
                offical_name: offical_name,
                offical_inn: offical_inn,
                offical_ogrn: offical_ogrn,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            console.error(e);
            if ("response" in e &&
                "data" in e.response &&
                "response" in e.response.data) {
                return e.response.data.response;
            }
        }
        return undefined;
    }
    static async UpdateCompanyLegalInfoFromSession(session_uuid, company_uuid, offical_addres, offical_name, addres) {
        try {
            const result = await axios.post((process.env.VUE_APP_API_PATH || "/") + "api/company/update-legal-info", {
                offical_name: offical_name,
                offical_addres: offical_addres,
                addres: addres,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                    "x-company-uuid": company_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            console.error(e);
        }
        return false;
    }
    static async GetCompanyListFromSession(session_uuid) {
        try {
            const result = await axios.get((process.env.VUE_APP_API_PATH || "/") + "api/company/get-list", {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            console.error(e);
        }
        return undefined;
    }
    static async GetCompanyItemFromSession(session_uuid, company_uuid) {
        try {
            const result = await axios.get((process.env.VUE_APP_API_PATH || "/") + "api/company/get", {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                    "x-company-uuid": company_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            console.error(e);
        }
        return null;
    }
    static async GetCompanyLegalInfoFromSession(session_uuid, company_uuid) {
        try {
            const result = await axios.get((process.env.VUE_APP_API_PATH || "/") + "api/company/get-legal-info", {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                    "x-company-uuid": company_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            console.error(e);
        }
        return null;
    }
    static async GetCompanyContactInfoFromSession(session_uuid, company_uuid) {
        try {
            const result = await axios.get((process.env.VUE_APP_API_PATH || "/") + "api/company/get-contact-info", {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                    "x-company-uuid": company_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            console.error(e);
        }
        return null;
    }
    static async GetCompanyBankInfoFromSession(session_uuid, company_uuid) {
        try {
            const result = await axios.get((process.env.VUE_APP_API_PATH || "/") + "api/company/get-bank-info", {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                    "x-company-uuid": company_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            console.error(e);
        }
        return null;
    }
    static async UpdateCompanyContactInfoFromSession(session_uuid, company_uuid, ceo_full_name, company_email, company_phone) {
        try {
            const result = await axios.post((process.env.VUE_APP_API_PATH || "/") + "api/company/update-contact-info", {
                ceo_full_name: ceo_full_name,
                company_email: company_email,
                company_phone: company_phone,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                    "x-company-uuid": company_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            console.error(e);
        }
        return false;
    }
    static async UpdateCompanyBankInfoFromSession(session_uuid, company_uuid, bank_rs, bik, bank_ks, bank_name) {
        try {
            const result = await axios.post((process.env.VUE_APP_API_PATH || "/") + "api/company/update-bank-info", {
                bank_rs: bank_rs,
                bik: bik,
                bank_ks: bank_ks,
                bank_name: bank_name,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                    "x-company-uuid": company_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            console.error(e);
        }
        return false;
    }
    static async UpdateCompanyNameFromSession(session_uuid, company_uuid, name) {
        try {
            const result = await axios.post((process.env.VUE_APP_API_PATH || "/") + "api/company/update-name", {
                name: name,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                    "x-company-uuid": company_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            console.error(e);
        }
        return false;
    }
}
ApiCompany.CurrentCompanyUUID = undefined;
export default ApiCompany;
