import i18n from "@/i18n/index.ts";
export default class DataFeedback {
}
DataFeedback.ContactsTableHeaders = [
    {
        text: i18n.t("table_headers.date"),
        value: "create_at",
        width: "10%",
    },
    {
        text: i18n.t("table_headers.name"),
        value: "name",
    },
    {
        text: i18n.t("table_headers.email"),
        value: "mail",
    },
    {
        text: i18n.t("table_headers.phone_number"),
        value: "phone",
    },
    {
        text: i18n.t("table_headers.action"),
        value: "action",
    },
];
DataFeedback.PartnersTableHeaders = [
    {
        text: i18n.t("table_headers.date"),
        value: "create_at",
        width: "10%",
    },
    {
        text: i18n.t("table_headers.business_name"),
        value: "business_name",
    },
    {
        text: i18n.t("table_headers.contact_name"),
        value: "contact_name",
    },
    {
        text: i18n.t("table_headers.email"),
        value: "email",
    },
    {
        text: i18n.t("table_headers.phone_number"),
        value: "phone_number",
    },
    {
        text: i18n.t("table_headers.action"),
        value: "action",
    },
];
