import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
import LeftMenuTab from "@/components/LeftMenu/LeftMenuTab.vue";
import PageHeader from "@/components/UI/PageHeader.vue";
import ApiFeedback from "@/api/ApiFeedback";
import ApiEnter from "@/api/ApiEnter";
import dayjs from "dayjs";
import sweetalert from "sweetalert";
let FeedbackPartners = class FeedbackPartners extends Vue {
    constructor() {
        super(...arguments);
        this.Message = null;
        this.Breadcrumbs = [
            {
                to: "/",
                text: this.$t("menu.home"),
            },
            {
                to: "/admin",
                text: this.$t("menu.cms"),
            },
            {
                to: "/admin",
                text: this.$t("feedback.feedback_messages"),
            },
            {
                to: "/cms/feedback/partners",
                text: this.$t("feedback.contact_us"),
            },
            {
                to: "/",
                text: this.$t("feedback.view_message"),
                disabled: true,
            },
        ];
    }
    formatDate(item) {
        return dayjs(item).format("DD-MM-YYYY (HH:mm:s)");
    }
    async LoadMessage() {
        const response = await ApiFeedback.GetFeedbackMessageByUUID(ApiEnter.CurrentSessionUUID, this.CurrentFeedbackUUID);
        if (response != undefined) {
            this.Message = response;
        }
        else {
            sweetalert({
                title: "Ошибка",
                text: "Ошибка с получением фидбека",
                icon: "error",
            });
            this.$router.back();
        }
    }
    created() {
        this.CurrentFeedbackUUID = this.$route.params.feedback_uuid;
        this.LoadMessage();
    }
    OpenFile() {
        if (this.Message) {
            window.open(this.Message.link, "_blank");
        }
    }
};
FeedbackPartners = __decorate([
    Component({
        components: { PageHeader, StandartTemplate, LeftMenuTab },
    })
], FeedbackPartners);
export default FeedbackPartners;
