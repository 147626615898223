import i18n from "@/i18n/index.ts";
class DataOrder {
}
DataOrder.Breadcrumbs = [
    {
        to: "/",
        text: i18n.t("breadcrumbs.home"),
    },
    {
        to: "/secure/shop/orders",
        text: i18n.t("breadcrumbs.e_shop"),
    },
    {
        to: "/secure/shop/orders",
        text: i18n.t("breadcrumbs.order_management"),
        disabled: true,
    },
];
DataOrder.TableHeaders = [
    {
        text: i18n.t("table_headers.date"),
        value: "create_at",
        width: "15%",
    },
    {
        text: i18n.t("table_headers.order"),
        value: "id",
    },
    {
        text: i18n.t("table_headers.customer"),
        value: "first_name",
    },
    {
        text: i18n.t("table_headers.email"),
        value: "email",
    },
    {
        text: i18n.t("table_headers.total"),
        value: "amount",
    },
    {
        text: i18n.t("table_headers.order_status"),
        value: "status",
    },
    {
        text: i18n.t("table_headers.payment_status"),
        value: "payment_status",
    },
];
export default DataOrder;
