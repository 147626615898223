import FaqQuestions from "@/views/Faq/FaqQuestions.vue";
import FaqCategories from "@/views/Faq/FaqCategories.vue";
import CreateQuestion from "@/views/Faq/CreateQuestion.vue";
import EditQuestion from "@/views/Faq/EditQuestion.vue";
const routes = [
    {
        path: "/secure/faq",
        name: "FaqQuestions",
        component: FaqQuestions,
    },
    {
        path: "/secure/faq/create",
        name: "CreateQuestion",
        component: CreateQuestion,
    },
    {
        path: "/secure/faq/edit/:faq_uuid",
        name: "EditQuestion",
        component: EditQuestion,
    },
    {
        path: "/secure/faq/categories",
        name: "FaqCategories",
        component: FaqCategories,
    },
];
export default routes;
