import FeedbackContacts from "@/views/CMS/Feedback/FeedbackContacts.vue";
import FeedbackContactsView from "@/views/CMS/Feedback/View/FeedbackContactsView.vue";
const routes = [
    {
        path: "/secure/cms/feedback/contacts",
        name: "FeedbackContacts",
        component: FeedbackContacts,
    },
    {
        path: "/cms/feedback/contact/view/:feedback_uuid",
        name: "FeedbackContactsView",
        component: FeedbackContactsView,
    },
];
export default routes;
