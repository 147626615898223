import i18n from "@/i18n/index.ts";
export default class DataSourceFiles {
}
DataSourceFiles.Breadcrumbs = [
    {
        to: "/",
        text: i18n.t("breadcrumbs.home"),
    },
    {
        to: "/",
        text: i18n.t("breadcrumbs.cms"),
    },
    {
        to: "/secure/cms/source-files",
        text: i18n.t("breadcrumbs.source_files_for_content"),
        disabled: true,
    },
];
DataSourceFiles.TableHeaders = [
    {
        text: i18n.t("table_headers.file_name"),
        value: "file_name",
    },
    {
        text: i18n.t("table_headers.anchor"),
        value: "anchor",
    },
    {
        text: i18n.t("table_headers.description"),
        value: "description",
    },
    {
        text: i18n.t("table_headers.created_at"),
        value: "create_at",
    },
    {
        text: i18n.t("table_headers.update_at"),
        value: "update",
    },
    {
        text: i18n.t("table_headers.action"),
        value: "action",
    },
];
