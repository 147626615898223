import i18n from "@/i18n/index.ts";
export default class DataOffers {
}
DataOffers.Breadcrumbs = [
    {
        to: "/",
        text: i18n.t("breadcrumbs.home"),
    },
    {
        to: "/secure/shop/offers",
        exact: true,
        text: i18n.t("breadcrumbs.shop"),
    },
    {
        to: "/secure/shop/offers",
        text: i18n.t("breadcrumbs.offers"),
        disabled: true,
    },
];
DataOffers.OfferCreateBreadcrumbs = [
    {
        to: "/",
        text: i18n.t("breadcrumbs.home"),
    },
    {
        to: "/secure/shop/offers",
        exact: true,
        text: i18n.t("breadcrumbs.shop"),
    },
    {
        to: "/secure/shop/offers",
        exact: true,
        text: i18n.t("breadcrumbs.offers"),
    },
    {
        to: "/admin/catalog/offers/create",
        text: i18n.t("breadcrumbs.offer_create"),
        disabled: true,
    },
];
DataOffers.OfferEditBreadcrumbs = [
    {
        to: "/",
        text: i18n.t("breadcrumbs.home"),
    },
    {
        to: "/secure/shop/offers",
        exact: true,
        text: i18n.t("breadcrumbs.shop"),
    },
    {
        to: "/secure/shop/offers",
        exact: true,
        text: i18n.t("breadcrumbs.offers"),
    },
    {
        to: "/admin/catalog/offers/create",
        text: i18n.t("breadcrumbs.offer_edit"),
        disabled: true,
    },
];
DataOffers.TableHeaders = [
    {
        text: i18n.t("table_headers.sorting"),
        value: "priority",
    },
    {
        text: i18n.t("table_headers.offer_name"),
        value: "name",
    },
    {
        text: i18n.t("table_headers.price"),
        value: "price",
    },
    {
        text: i18n.t("table_headers.data_gb"),
        value: "data",
    },
    {
        text: i18n.t("table_headers.action"),
        value: "action",
    },
];
