import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import PageHeader from "@/components/UI/PageHeader.vue";
import ApiEnter from "@/api/ApiEnter";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
import DataOffers from "@/data/AdminPanel/DataOffers";
import ApiOffer from "@/api/ApiOffer";
import sweetalert from "sweetalert";
import { Getter } from "vuex-class";
import EUserEnum from "@/struct/user/EUserEnum";
let Offers = class Offers extends Vue {
    constructor() {
        super(...arguments);
        this.Breadcrumbs = DataOffers.Breadcrumbs;
        this.TableHeaders = DataOffers.TableHeaders;
        this.TableItems = [];
        this.OffersCategories = [];
        this.HasRequest = false;
    }
    CanAddAndEditAccess() {
        return (this.GetCurrentSessionUser != undefined &&
            (this.GetCurrentSessionUser.system_role == EUserEnum.SystemRole.Admin ||
                this.GetCurrentSessionUser.system_role ==
                    EUserEnum.SystemRole.SuperAdmin ||
                this.GetCurrentSessionUser.system_role == EUserEnum.SystemRole.Editor));
    }
    CanDeleteAccess() {
        return (this.GetCurrentSessionUser != undefined &&
            (this.GetCurrentSessionUser.system_role == EUserEnum.SystemRole.Admin ||
                this.GetCurrentSessionUser.system_role ==
                    EUserEnum.SystemRole.SuperAdmin));
    }
    async getOffersCategories() {
        try {
            this.OffersCategories = await ApiOffer.GetOfferCategories(ApiEnter.CurrentSessionUUID);
        }
        catch (e) {
            console.error(e);
        }
    }
    async getOffers() {
        this.HasRequest = true;
        try {
            this.TableItems = await ApiOffer.GetOffers(ApiEnter.CurrentSessionUUID);
        }
        catch (e) {
            console.error(e);
        }
        this.HasRequest = false;
    }
    async ChangePublish(item) {
        try {
            const response = await ApiOffer.UpdateOfferPublish(item.publish ? 1 : 0, ApiEnter.CurrentSessionUUID, item.uuid);
            if (typeof response == "boolean") {
                sweetalert({
                    title: this.$t("general.success"),
                    text: this.$t("general.success_updated"),
                    icon: "success",
                }).then(() => {
                    this.getOffers();
                });
            }
            else {
                await sweetalert({
                    title: this.$t("general.error"),
                    text: this.$t("general.request_error"),
                    icon: "info",
                });
            }
        }
        catch (e) {
            console.error(e);
            await sweetalert({
                title: this.$t("general.error"),
                text: this.$t("general.request_error"),
                icon: "info",
            });
        }
    }
    async DeleteItem(offer_uuid) {
        sweetalert({
            title: this.$t("general.confirm"),
            text: this.$t("offers.confirm_delete"),
            buttons: [
                this.$t("general.no"),
                this.$t("general.yes"),
            ],
        }).then(async (isConfirm) => {
            if (isConfirm == true) {
                const response = await ApiOffer.DeleteOffer(ApiEnter.CurrentSessionUUID, offer_uuid);
                if (typeof response == "boolean") {
                    await sweetalert({
                        title: this.$t("general.success"),
                        text: this.$t("general.success_deleted"),
                        icon: "success",
                    });
                    await this.getOffers();
                }
                else {
                    await sweetalert({
                        title: this.$t("general.error"),
                        text: this.$t("general.request_error"),
                        icon: "info",
                    });
                }
            }
        });
    }
    mounted() {
        this.getOffersCategories();
        this.getOffers();
    }
};
__decorate([
    Getter("getCurrentSessionUser")
], Offers.prototype, "GetCurrentSessionUser", void 0);
Offers = __decorate([
    Component({
        components: { StandartTemplate, PageHeader },
    })
], Offers);
export default Offers;
