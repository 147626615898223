import Users from "@/views/AdminPanel/Users.vue";
import Companies from "@/views/AdminPanel/Companies.vue";
import EditUser from "@/views/AdminPanel/EditUser.vue";
import EditCompany from "@/views/AdminPanel/EditCompany.vue";
import Offers from "@/views/AdminPanel/Offers.vue";
import OffersCreate from "@/views/AdminPanel/OfferCreate.vue";
import OfferEdit from "@/views/AdminPanel/OfferEdit.vue";
const routes = [
    {
        path: "/secure/admin/users",
        name: "AdminUsers",
        component: Users,
    },
    {
        path: "/secure/admin/companies",
        name: "AdminCompanies",
        component: Companies,
    },
    {
        path: "/secure/admin/edit-user/:user_uuid",
        name: "EditUser",
        component: EditUser,
    },
    {
        path: "/secure/admin/edit-company/:company_uuid",
        name: "EditCompanies",
        component: EditCompany,
    },
    {
        path: "/secure/shop/offers",
        name: "Offers",
        component: Offers,
    },
    {
        path: "/secure/shop/offers/create",
        name: "OffersCreate",
        component: OffersCreate,
    },
    {
        path: "/secure/shop/offers/edit/:offer_uuid",
        name: "OfferEdit",
        component: OfferEdit,
    },
];
export default routes;
