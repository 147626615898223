import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import PageHeader from "@/components/UI/PageHeader.vue";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
import DataOffers from "@/data/AdminPanel/DataOffers";
import ApiEnter from "@/api/ApiEnter";
import ApiOffer from "@/api/ApiOffer";
import sweetalert from "sweetalert";
import ApiAdmin from "@/api/ApiAdmin";
import { EIncludeTariffs } from "@/struct/offers/EIncludeTariffs";
let OfferCreate = class OfferCreate extends Vue {
    constructor() {
        super(...arguments);
        this.Breadcrumbs = DataOffers.OfferCreateBreadcrumbs;
        this.OffersCategories = [];
        this.newOffer = {
            name: "",
            priority: "",
            publish: true,
            price: "",
            renewalPrice: "",
            additional_price_info: "",
            sticker_text: "",
            data: "",
            sms: "",
            min: "",
            additional_data: "",
            overview: "",
            note: "",
            display_offers: false,
            display_home: false,
            display_for_new: false,
            display_for_current: false,
            prospects_info: null,
            title: "",
            description: "",
            additional_description: "",
            selectTariff: null,
            keywords: "",
            url: "",
        };
        this.tariffItems = [
            {
                text: "Нет",
                value: null,
            },
            {
                text: "Wink",
                value: EIncludeTariffs.Wink,
            },
            {
                text: "Wink+",
                value: EIncludeTariffs.WinkPlus,
            },
        ];
        this.HasLoading = false;
        this.emsOffers = [];
        this.selectOffer = null;
        this.emsOffer = null;
    }
    Translit(str) {
        let ru = "А-а-Б-б-В-в-Ґ-ґ-Г-г-Д-д-Е-е-Ё-ё-Є-є-Ж-ж-З-з-И-и-І-і-Ї-ї-Й-й-К-к-Л-л-М-м-Н-н-О-о-П-п-Р-р-С-с-Т-т-У-у-Ф-ф-Х-х-Ц-ц-Ч-ч-Ш-ш-Щ-щ-Ъ-ъ-Ы-ы-Ь-ь-Э-э-Ю-ю-Я-я".split("-");
        let en = "A-a-B-b-V-v-G-g-G-g-D-d-E-e-E-e-E-e-ZH-zh-Z-z-I-i-I-i-I-i-J-j-K-k-L-l-M-m-N-n-O-o-P-p-R-r-S-s-T-t-U-u-F-f-H-h-TS-ts-CH-ch-SH-sh-SCH-sch-'-'-Y-y-'-'-E-e-YU-yu-YA-ya".split("-");
        let res = "";
        for (let i = 0, l = str.length; i < l; i++) {
            let s = str.charAt(i), n = ru.indexOf(s);
            if (n >= 0) {
                res += en[n];
            }
            else {
                res += s;
            }
        }
        return res;
    }
    GenerateSeoUrl() {
        let url = this.Translit(this.newOffer.name.replace(/[\s]+/gi, "-"));
        this.newOffer.url = url.replace(/[^0-9a-z_-]+/gi, "").toLowerCase();
    }
    filterOffer() {
        this.emsOffer = this.emsOffers.find((item) => item.id === this.selectOffer);
    }
    ValidateSeoUrl() {
        this.newOffer.url = this.Translit(this.newOffer.url.replace(/[. ,$@!^()'*]+/g, "-").toLowerCase());
    }
    async getOffersCategories() {
        try {
            this.OffersCategories = await ApiOffer.GetOfferCategories(ApiEnter.CurrentSessionUUID);
        }
        catch (e) {
            console.error(e);
        }
    }
    async OnClickSubmit() {
        // this.HasLoading = true;
        if (ApiEnter.CurrentSessionUUID != undefined) {
            this.$forceUpdate();
            let service = this.tariffItems.find((item) => item.value == this.newOffer.selectTariff);
            if (service.value == null) {
                service = null;
            }
            const offer_uuid = await ApiOffer.CreateOffer(ApiEnter.CurrentSessionUUID, this.newOffer.name, this.newOffer.priority, this.newOffer.publish ? 1 : 0, this.newOffer.price, this.newOffer.data, this.newOffer.sms, this.newOffer.min, this.newOffer.additional_data, this.newOffer.additional_price_info, this.newOffer.sticker_text, this.newOffer.overview, this.newOffer.note, this.newOffer.display_offers ? 1 : 0, this.newOffer.display_home ? 1 : 0, this.newOffer.display_for_new ? 1 : 0, this.newOffer.display_for_current ? 1 : 0, this.newOffer.prospects_info != null
                ? (await ApiAdmin.UploadFile(ApiEnter.CurrentSessionUUID, this.newOffer.prospects_info))
                : "", this.newOffer.title, this.newOffer.description, this.newOffer.additional_description, this.emsOffer ? this.emsOffer?.id : null, this.emsOffer ? this.emsOffer?.name : null, this.newOffer.keywords, this.newOffer.url, JSON.stringify(service), this.newOffer.renewalPrice);
            if (offer_uuid == undefined || offer_uuid.length != 36) {
                await sweetalert({
                    title: this.$t("general.error"),
                    text: this.$t("general.request_error"),
                    icon: "info",
                });
                return;
            }
            sweetalert({
                title: this.$t("general.success"),
                text: this.$t("general.success_created"),
                icon: "success",
            }).then(() => {
                this.$forceUpdate();
                this.$router.push(`/secure/shop/offers`);
            });
        }
        this.HasLoading = false;
    }
    async getCMSOffers() {
        try {
            const response = await ApiOffer.GetEMSOffers(ApiEnter.CurrentSessionUUID, "main");
            if (typeof response != "boolean") {
                this.emsOffers = response;
            }
        }
        catch (e) {
            console.error(e);
        }
    }
    created() {
        this.getCMSOffers();
        this.getOffersCategories();
    }
};
OfferCreate = __decorate([
    Component({
        components: { StandartTemplate, PageHeader },
    })
], OfferCreate);
export default OfferCreate;
